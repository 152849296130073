import { html } from 'lit';
import IVPLitElementBase from './lit-element-base';

import "@ui5/webcomponents/dist/BusyIndicator";

import "./loader.scss";

export default class IVPLoader extends IVPLitElementBase {

	static get tag() { return 'ivp-loader' }

    static get properties() {
        return {

        };
    }

    constructor() {
        super();
    }

    render() {
        return html`<ui5-busy-indicator active size="Small"></ui5-busy-indicator>`;
    }

    createRenderRoot() {
        return this;
    }

    static Show(elContainer) {
        if (elContainer == null)
			elContainer = document.body;

		let vels = document.querySelectorAll("ivp-loader")
		if (vels != null)
			vels.forEach((el)=>{ el.remove() })
        let loader = document.createElement(this.tag);
        elContainer.prepend(loader);

        return loader;
    }

    static Remove() {
        let vels = document.querySelectorAll("ivp-loader")
		if (vels != null)
			vels.forEach((el)=>{ el.remove() })
    }
}

IVPLoader.RegisterElement()
