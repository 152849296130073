
import { html } from 'lit'
import IVPLitElementBase from './lit-element-base'
import IVPSortableList from './sortable-list'

import "./widget-list.scss"


export default class IVPWidgetList extends IVPSortableList {

	static get tag() { return 'ivp-widget-list' }

    static get properties() {
        return {			
			selectable_items: { type: Array },			
        };
    }

    constructor() {
        super();

		this.selectable_items = []
    }

    render() {
        return html`
		<div>
			${this.selectable_items==null || this.selectable_items.length==0?				
				html`<input class="select" type="text" @keypress="${this._OnKeyPress}"/>`:
				html`
					<select class="select">
						<option value="" disabled selected>Seleziona</option>
						${this.selectable_items.map((item, index)=>{
							let label = item
							if (this.label_field != null)
								label = item[this.label_field]
							return html`
								<option value="${index}">${label}</option>
							`
						})}					
					</select>`
			}
			<ui5-button icon="accept" title="Aggiunge la fase al modello" @click="${this._OnAddItem}"></ui5-button>
		</div>
		${this.RenderList()}	
		`;
    }

	_OnKeyPress(evt) {
		if (evt.code === 'Enter')
			this._OnAddItem(evt)
	}

    async _OnAddItem(evt) {
		setTimeout(()=>{
			let select = this.querySelector(".select")
			let item = select.value
			if (this.selectable_items!=null && this.selectable_items.length>0)
				item = this.selectable_items[select.value]		
			this.AppendItem(item)
			select.value = ""
		},10)
	}
}

IVPWidgetList.RegisterElement()
