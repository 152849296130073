import './labelling.scss';

import { html } from 'lit';
import IVPDialog from '../components/dialog';
import axios from 'axios';

import '@ui5/webcomponents/dist/Link'

export default class LabellingDialog extends IVPDialog {

    static get tag() { return 'labelling-dialog' }

    constructor() {
        super();
        this.title = "Etichettatura";
        this.shoes = [];
        this.itemsPerPage = 10;
        this.currentPage = 1;
        this.confirm_button_label = 'Stampa';
        this.isLoading = true;
    }

    _RenderBody() {
        if (this.isLoading)
            return html`
                <div class="inline-loader">
                    <div>Caricamento...</div>
                    <ui5-busy-indicator active size="Medium"></ui5-busy-indicator>
                </div>
            `

        return html`
            <div style="display: none;" class="manual">
                <field>
                    <ui5-label show-colon ></ui5-label>
                    <ui5-input value=""></ui5-input>
                </field>
                <field>
                    <ui5-label show-colon ></ui5-label>
                    <ui5-input value=""></ui5-input>
                </field>
                <field>
                    <ui5-label show-colon ></ui5-label>
                    <ui5-input value=""></ui5-input>
                </field>
                <field>
                    <ui5-label show-colon ></ui5-label>
                    <ui5-input value=""></ui5-input>
                </field>
                <field>
                    <ui5-label show-colon ></ui5-label>
                    <ui5-input value=""></ui5-input>
                </field>
            </div>
            ${this._RenderPagination()}
            <div>
                <ui5-checkbox id="toggle" @change=${this._ToggleAll} text="Seleziona / deseleziona tutto"></ui5-checkbox>
                <!--<ui5-input id="searchInput" placeholder="Filtra ..." style="width: 100%">
                    <ui5-icon id="searchIcon" slot="icon" name="search"></ui5-icon>
                </ui5-input>-->
            </div>

            ${this._RenderTable()}

            ${this._RenderPagination()}
		`;
    }

    _RenderTable() {
        const tableColumns = [
            {
                caption: ''
            }, {
                caption: 'SKU'
            }, {
                caption: 'Nome'
            }, {
                caption: 'Taglia'
            }, {
                caption: 'Colore'
            }, {
                caption: 'GTIN'
            }, {
                caption: 'Quantità',
                width: '100px'
            }

        ]
        return html`
            <ui5-table>
                ${tableColumns.map(col => html`
                    <ui5-table-column style="width: ${col.width};" slot="columns">
                        <span>${col.caption}</span>
                    </ui5-table-column>
                `)}

                ${this.shoes.slice((this.currentPage - 1) * this.itemsPerPage, this.currentPage * this.itemsPerPage).map(shoe => this._FormatShoeItem(shoe))}
            </ui5-table>
        `;
    }

    _ToggleAll(evt) {
        this.shoes.forEach(shoe => {
            shoe.isSelected = evt.currentTarget.hasAttribute('checked');
        })
        this.requestUpdate();
    }

    _RenderPagination() {
        const pagesNumber = Math.ceil(this.shoes.length / this.itemsPerPage);
        if (pagesNumber <= 1)
            return html``;

        return html`
            <div class="pagination">
                <ui5-link @click=${() => { this._PagePrev() }}>Precedente</ui5-link>
                <div>Pagina ${this.currentPage} di ${pagesNumber}</div>
                <ui5-link @click=${() => { this._PageNext() }}>Successivo</ui5-link>
            </div>
        `
    }

    _PagePrev() {
        if (this.currentPage > 1)
            this.currentPage--;
        this.requestUpdate()
    }

    _PageNext() {
        if (this.currentPage < this.shoes.length / this.itemsPerPage)
            this.currentPage++;
        this.requestUpdate()
    }

    _FormatShoeItem(shoe) {
        try {
            const info = shoe['Variante'].split(';');
            const sku = info[0];
            const color = info[1];
            const size = info[2];

            return html`
                <ui5-table-row>
                    <ui5-table-cell @click=${() => { this._ShoeItemOnClick(shoe) }}><ui5-checkbox ?checked=${shoe.isSelected}></ui5-checkbox></ui5-table-cell>
                    <ui5-table-cell>${sku}</ui5-table-cell>
                    <ui5-table-cell>${shoe['Nome Prodotto *']}</ui5-table-cell>
                    <ui5-table-cell>${size}</ui5-table-cell>
                    <ui5-table-cell>${color}</ui5-table-cell>
                    <ui5-table-cell>${shoe['GTIN *']}</ui5-table-cell>
                    <ui5-table-cell style="width: 100px;">
                        <div>
                            <input class="quantity" type="number" @change="${e => { this.OnQtyUpdate(e, shoe) }}" @click="${this.OnQty}" value="1">
                        </div>
                    </ui5-table-cell>
                </ui5-table-row>
            `;
        } catch (error) {

        }
    }

    OnQtyUpdate(e, _shoe) {
        let elQty = e.currentTarget;
        if (isNaN(elQty.value) || elQty.value < 1) {
            elQty.value = 1;
        }
        this.shoes.find(shoe => shoe == _shoe).quantity = elQty.value;
    }

    OnQty(e) {
        e.stopPropagation();
    }

    _ShoeItemOnClicked() {
        const hasChecked = this.shoes.some(cb => cb.isSelected);
        const hasUnchecked = this.shoes.some(cb => !cb.isSelected);
        const elToggle = this.querySelector('#toggle');
        elToggle.indeterminate = hasChecked && hasUnchecked;
        elToggle.checked = hasChecked;
    }

    _ShoeItemOnClick(_shoe) {
        this.updateComplete.then(() => {
            this._ShoeItemOnClicked();
        })
        if (_shoe.isSelected) {
            this.shoes.find(shoe => shoe == _shoe).isSelected = false;
            this.requestUpdate();
            return;
        }
        this.shoes.find(shoe => shoe == _shoe).isSelected = true;
        this.requestUpdate();
    }

    async Show() {
        return IVPDialog.Show(this)
    }

    async Init() {
        try {
            const res = await axios.get('/production/getLabels');
            let arrayOfArrays = res.data;
            let keys = arrayOfArrays.shift();
            arrayOfArrays = arrayOfArrays.filter(arr => arr[keys.indexOf('Variante')] && arr[keys.indexOf('Variante')] != '');
            let arrayOfObjects = arrayOfArrays.map(function (values) {
                return keys.reduce(function (obj, key, index) {
                    obj[key] = values[index];
                    return obj;
                }, {});
            });
            this.shoes = arrayOfObjects;
        } catch (error) {

        } finally {
            this.isLoading = false;
            this.requestUpdate();
        }
    }

    async OnConfirm(e) {
        const btn = e.currentTarget;
        try {
            btn.disabled = true;
            const selectedShoes = this.shoes.filter(shoe => shoe.isSelected == true);
            if (selectedShoes.length > 0)
                await axios.post(`${window.Sets.ServiceAddress}/printLabel`, { labels: selectedShoes })
        } catch (error) {
            console.error(error);
        } finally {
            btn.disabled = false;
        }
        return this.Ok()
    }

    async OnCancel() {
        return this.Cancel()
    }
}

LabellingDialog.RegisterElement()