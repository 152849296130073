
import DataProvider from "./data_provider"

export default class Auth {

	static async IsLogged() {
		let user = window.localStorage.getItem("user")
		if (user != "" && user != null) {
			return await window.Auth.Login(user)
		}

		return false
	}

	static async Login(User) {

		try {
			let usr = await DataProvider.Users.Get(User)		
			if (usr != null) {
				Auth.user = usr				
				window.localStorage.setItem("user",User )
				
				let st = await DataProvider.Users.Get(User)		
				return true
			}			
		}
		catch(error) {
			const errorCode = error.code;
			const errorMessage = error.message;
			// ...
			console.error(error)
		}

		return false
	}

	static async Logout() {
		window.localStorage.removeItem("user")
		return true
	}
}

window.Auth = Auth