import { html } from 'lit';
import IVPSectionBase from '../components/section';
import IVPRouter from '../libs/router';

import "./sections.scss";
import DataProvider from '../data_provider';

import ProductionFinalizationDialog from "../dialogs/production-finalization"
import LabellingDialog from '../dialogs/labelling';


export default class IVPSectionHome extends IVPSectionBase {

	static get tag() { return 'ivp-section-home' }

	static get properties() {
		return {

		};
	}

	constructor() {
		super();


	}

	render() {
		return html`
			<!--ui5-title level="H2"></ui5-title-->
			<nav>
				<a @click="${this._OnNotarize}">
					<ui5-icon name="goal"></ui5-icon>
					<div>Notarizzazione</div>
					<p>Modulo di notarizzazione prodotti</p>
				</a>			
				<a href="/archivi/produzione" @click="${this._OnItemClick}">
					<ui5-icon name="badge"></ui5-icon>
					<div>Produzione</div>
					<p>Archivio Lotti Produzione</p>
				</a>			
				<a href="/archivi/modelli" @click="${this._OnItemClick}">
					<ui5-icon name="product"></ui5-icon>
					<div>Modelli</div>
					<p>Archivio Modelli Prodotti</p>
				</a>
				<a href="/archivi/materiali" @click="${this._OnItemClick}">
					<ui5-icon name="dimension"></ui5-icon>
					<div>Materiali</div>
					<p>Archivio Materiali</p>
				</a>						
				<a href="/archivi/lavorazioni" @click="${this._OnItemClick}">
					<ui5-icon name="clinical-tast-tracker"></ui5-icon>
					<div>Lavorazioni</div>
					<p>Archivio Fasi di lavorazione</p>
				</a>
				<a  href="/impostazioni/opzioni" @click="${this._OnItemClick}">
					<ui5-icon name="user-settings" ></ui5-icon>
					<div>Opzioni</div>
					<p>Opzioni ed impostazioni</p>
				</a>
				<a  @click="${this._OnLabelling}">
					<ui5-icon name="tag" ></ui5-icon>
					<div>Etichettature</div>
					<p>Stampa etichette</p>
				</a>
			</nav>
		`;
	}

	firstUpdated() {
		// Attiva il router
		if (window.router == null) {
			window.router = new IVPRouter({
				mode: 'history',
				root: '/'
			});
		}
	}

	_OnItemClick(evt) {
		evt.preventDefault()
		window.router.navigate(new URL(evt.currentTarget.href).pathname)
	}

	async _OnLabelling() {
		let dlg = new LabellingDialog();
		await dlg.Show()
	}

	async _OnNotarize() {
		let dlg = new ProductionFinalizationDialog()
		await dlg.Show()
	}
}

IVPSectionHome.RegisterElement()

IVPRouter.Register("/", () => {
	if (document.querySelector("#CONTENT") != null)
		document.querySelector("#CONTENT").innerHTML = "<ivp-section-home></ivp-section-home>"
})