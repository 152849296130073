import { LitElement, html } from 'lit';

import regeneratorRuntime from "regenerator-runtime";

export default class IVPLitElementBase extends LitElement {

	static get tag() { return "TAG" }

	get TransparentImageSrc() { return "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" }

    static get properties() {
        return {

        };
    }

    constructor() {
        super();
    }

    createRenderRoot() {
        return this;
    }

	static RegisterElement() {
		customElements.define(this.tag, this);
	}
}
