
import {  collection, query, where, setDoc, doc, getDocs, deleteDoc, getDoc, addDoc, orderBy } from "firebase/firestore"; 


export default class DataStoreBase {

	constructor(db_archive) {
		this.db_archive = db_archive		
	}

	FillRow(row) {
		return row
	}

	async Get(id) {
		id = id.toString()
		let docSnap = await getDoc(doc(this.db_archive, id));
		if (docSnap.exists() == false)
			return null		
		let row = docSnap.data()
		if (row == null)
			return null
		row["id"] = docSnap.id
		await this.UpdateLinkedTables()

		return this.FillRow(row);
	}

	async UpdateLinkedTables() {

	}

	async GetList(filter, orderby) {
	
		let constraints = []		
		if (filter != null && filter.length>0)
			filter.forEach((fl)=>{
				constraints.push(where(fl.field,fl.condition,fl.value))
			})

		if (orderby != null && orderby.length>0)
			orderby.forEach((ob)=>{
				constraints.push(orderBy(ob.field,ob.direction))
			})					

		let data = await getDocs(query(this.db_archive,...constraints))

		let data_out = []
		await this.UpdateLinkedTables()
		data.forEach(async (doc)=>{			
			let row = doc.data()
			row["id"] = doc.id
			data_out.push(this.FillRow(row))
		})
		//console.log(data_out)
		return data_out
	}

	async Delete(id) {
		await deleteDoc(doc(this.db_archive, id));
	}

	async Save(data, id) {
		if (id == null) 
			await addDoc(this.db_archive,data);
		else
		 	await setDoc(doc(this.db_archive,id),data);
	}	
}
