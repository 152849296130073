
import "./libs/router"
import IVPLoader from "./components/loader"
import IVPLogin from "./components/login"

import DataProvider from "./data_provider"
import Auth from "./auth"


import "./components/side-navigation"
import "./sections/home"
import "./sections/settings"
import "./sections/materials"
import "./sections/phases"
import "./sections/models"
import "./sections/production-batches"
import "./sections/production-log"

import "@ui5/webcomponents/dist/Icon"
import "@ui5/webcomponents-icons/dist/employee.js"
import "@ui5/webcomponents-icons/dist/log"
import "@ui5/webcomponents-icons/dist/menu"
import "@ui5/webcomponents-icons/dist/goal"
import "@ui5/webcomponents-icons/dist/tag"
import "@ui5/webcomponents-icons/dist/add"
import "@ui5/webcomponents-icons/dist/home"
import "@ui5/webcomponents-icons/dist/group"
import "@ui5/webcomponents-icons/dist/locate-me"
import "@ui5/webcomponents-icons/dist/badge"
import "@ui5/webcomponents-icons/dist/product"
import "@ui5/webcomponents-icons/dist/dimension"
import "@ui5/webcomponents-icons/dist/clinical-tast-tracker"
import "@ui5/webcomponents-icons/dist/user-settings"
import "@ui5/webcomponents-fiori/dist/SideNavigation"
import "@ui5/webcomponents-fiori/dist/SideNavigationItem"
import "@ui5/webcomponents-fiori/dist/SideNavigationSubItem"
import "@ui5/webcomponents-fiori/dist/ShellBar"
import "@ui5/webcomponents/dist/Title";

import logo from './images/logo.svg'
import logout from './images/logout.svg'

import Notarizator from './libs/notarizator'

window._DoIt = async () => {
	try {
	let model_data = await DataProvider.Models.Get("X73s64HDG4O02kzs2cW8")

	let ret = await Notarizator.Notarize(model_data.name+"_"+"X73s64HDG4O02kzs2cW8", window.Sets.IpfsGateway+"QmbTwxx7bJ7EmQZhhMUfUY7WP3SA9UPnT1ij1hTuKV2196/",model_data)

	console.log(ret)
	}
	catch(Ex) {
		console.log(Ex)
	}
}


window.addEventListener("load",async ()=>{

	if (await window.Auth.IsLogged() == true) {
		document.body.innerHTML = `
			<header>
				<ui5-shellbar
					primary-title="Blockchain Notarizer App"
					secondary-title="Utente: ${window.Auth.user.id}"
				>
					<img slot="logo" src="${logo}"/>
					<ui5-button icon="menu" slot="startButton" id="startButton"></ui5-button>
						
				</ui5-shellbar>     
			</header>

			<div class="wrapper">
				<ivp-side-navigation></ivp-side-navigation>

				<div id="CONTENT" class="content">
					<ivp-section-home></ivp-section-home>
				</div>	
			</div>	
		`
		const sideNavigation = document.querySelector("ivp-side-navigation");	
		document.querySelector("#startButton").addEventListener("click", event => sideNavigation.collapsed = !sideNavigation.collapsed);	
	} else {
		document.body.innerHTML = `
			<ivp-login></vp-login>	
		`
	}
})


