import { html } from 'lit';
import IVPLitElementBase from '../components/lit-element-base';

import "./section.scss";

export default class IVPSectionBase extends IVPLitElementBase {

	// classe base static get tag() { return 'ivp-section-materials' }

    static get properties() {
        return {
			
        };
    }

    constructor() {
		super();		
    }

	connectedCallback() {
		super.connectedCallback()
		this.classList.add("section")
	}

	firstUpdated() {
		this.Init()
	}

	async Init() {
		
	}
}
