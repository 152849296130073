import { html } from 'lit';
import IVPDialog from '../components/dialog';
import IVPLoader from '../components/loader';
import Ipfs from '../libs/ipfs'



export default class CommonEditDialog extends IVPDialog {
	
	// Classe base static get tag() { return 'material-dialog' }

	constructor() {
		super()
		
		this.title = "Titolo"
		this.item_name = ""
		this.db_archive = null 
		this.record_id = null
		this.data = {
			name: "",
			description: "",
			media_url: "",
			code: ""
		}
	}

	_RenderBody() {
		return html`
			<div class="row">
				<div class="col">
					<field>
						<ui5-label required show-colon >Nome</ui5-label>
						<ui5-input class="name" placeholder="Nome ${this.item_name}" value="${this.data['name']}"></ui5-input>
					</field>
					<field>
						<ui5-label required show-colon>Descrizione</ui5-label>
						<ui5-textarea class="description" placeholder="Descrizione ${this.item_name}" maxlength="255" show-exceeded-text value="${this.data['description']}"></ui5-textarea>
					</field>
				</div>
				<div class="col">
					<field>
						<ivp-media-uploader ratio="0" media_url="${this.data["media_url"]}"></ivp-media-uploader>
					</field>		
				</div>				
			</div>								
		`;
	}

	async Show(id) {
		this.record_id = id
		if (id != null) {
			IVPLoader.Show()
			this.data = await this.db_archive.Get(id)
			IVPLoader.Remove()
		}
		return IVPDialog.Show(this)
	}	

	async OnConfirm() {
		
		let name = this.querySelector(".name")
		let description = this.querySelector(".description")
		let media_uploader = this.querySelector("ivp-media-uploader")

		if(name.value.trim() == "") {
			name.valueState = "Error"
			name.focus()
			return
		}
		if(description.value.trim() == "") {
			description.valueState = "Error"
			description.focus()
			return
		}
		if (media_uploader.media_url == "") {
			media_uploader.error_string = "E' necessario impostare un'immagine"			
			return
		}
		this.data["name"] = name.value.trim()
		this.data["description"] = description.value.trim()
		
		this.WaiterShow()
		if (media_uploader.media_url.startsWith("https://") == false) {
			let ipfs_hash = await Ipfs.UploadToPinata(this.data["name"],media_uploader.blob,Sets.Pinata_JWT)
			this.data["media_url"] = Sets.IpfsGateway + ipfs_hash
		}
		else {
			this.data["media_url"] = media_uploader.media_url
		}

		if(this.data.code == "" || typeof this.data.code === "undefined")
			this.data.code = this.getCode(this.data.name);

		await this.db_archive.Save(this.data, this.record_id)
		
		this.WaiterHide()

		return this.Ok()
	}

	async OnCancel() {
		return this.Cancel()
	}	

	getCode(name){
		let date = new Date();
		let code = name.toUpperCase()
		code = code.replaceAll(" ", "_");
		code += "_" + date.getFullYear() + "-" + (date.getMonth()+1).toString().padStart(2, "0") +"-"+date.getDate().toString().padStart(2, "0");
		code += "_" + date.getHours().toString().padStart(2, "0") + ":" +  date.getMinutes().toString().padStart(2, "0")
		return code;
	}
}