import { html } from 'lit';
import CommonEditDialog from './common-edit';
import Ipfs from '../libs/ipfs'

import DataProvider from '../data_provider'


export default class MaterialDialog extends CommonEditDialog {
	
	static get tag() { return 'material-dialog' }

	constructor() {
		super()
		
		this.title = "Modifica Materiale"
		this.item_name = "Materiale"
		this.db_archive = DataProvider.Materials
		
	}	
}

MaterialDialog.RegisterElement()