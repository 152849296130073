import axios from 'axios'


export default class Ipfs {

	static async _DoIO(name, form_data, pinata_jwt) {

		const metadata = JSON.stringify({
			"name": name,			
		});
		form_data.append('pinataMetadata', metadata);

		//pinataOptions are optional
		const pinataOptions = JSON.stringify({
			cidVersion: 0,
			customPinPolicy: {
				regions: [
					{
						id: 'FRA1',
						desiredReplicationCount: 2
					},
					{
						id: 'NYC1',
						desiredReplicationCount: 2
					}
				]
			}
		});
		form_data.append('pinataOptions', pinataOptions);

		return await axios
			.post(
				`https://api.pinata.cloud/pinning/pinFileToIPFS`, 
				form_data, {
				maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
				headers: {
					'Content-Type': `multipart/form-data; boundary=${form_data._boundary}`,					
					"authorization": "Bearer "+ pinata_jwt,					
				}
			})
	}

	static async UploadToPinata(name, vblobs, pinata_jwt) {

		try {
			let data = new FormData();
					
			if (Array.isArray(vblobs) == false) {
				data.append("file", vblobs);	
			}
			else {
				vblobs.forEach(element => {			
					data.append("file", element.blob, name+"/"+element.name);	
				});
			}

			let ret = await Ipfs._DoIO(name, data, pinata_jwt)

			return `${ret.data.IpfsHash}`
		}
		catch (Ex) {
			console.error();(Ex)
		}

		return null;
	}	
}