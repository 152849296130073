import { html } from 'lit';
import IVPDialog from '../components/dialog';
import IVPWidgetList from '../components/widget-list';
import Ipfs from '../libs/ipfs'
import MobileDetect from 'mobile-detect'
import DataProvider from '../data_provider'
import Notarizator from '../libs/notarizator'
import "@ui5/webcomponents/dist/RadioButton";
import { Html5Qrcode } from "html5-qrcode" // => https://github.com/mebjas/html5-qrcode

import "./production-finalization.scss"
import axios from 'axios';
import NFC from '../libs/nfc_';

import beep from '../beep.wav';

export default class ProductionFinalizationDialog extends IVPDialog {

	static get tag() { return 'product-finalization-dialog' }

	get MODE() {
		return {
			QRCODE_SCAN: "QRCODE_SCAN",
			FORM_AUTO: "FORM_AUTO",
			FORM_MANUAL: "FORM_MANUAL",
			FORM_WRITE_URL: "FORM_WRITE_URL",
		}
	}

	static get properties() {
		return {
			current_model: { type: String },
			mode: { type: String }
		};
	}

	constructor() {
		super()

		let md = new MobileDetect(window.navigator.userAgent);

		this.title = "Finalizza Produzione"
		this.confirm_button_label = md.mobile() ? "" : "Chiudi"
		this.record_id = null
		this.serial = "SAMPLE"
		this.number = ""
		this.chip = ""
		this.models_list = []
		this.model_image = ""
		this.current_model = ""
		this.mode = this.MODE.QRCODE_SCAN

		this.nfc_read_timer_handle = null
	}

	_RenderBody() {

		this.fn = this.RenderQrCodeScan
		if (this.mode == this.MODE.FORM_WRITE_URL)
			this.fn = this.RenderUrlForm
		else if (this.mode != this.MODE.QRCODE_SCAN)
			this.fn = this.RenderForm

		return html`
		<div class="row">
			<field class="horizontal">				
				<ui5-radio-button text="Automatico" class="mode_auto" checked  name="mode" @change="${this._OnModeChange}"></ui5-radio-button>
				<ui5-radio-button text="Manuale" class="mode_manual" value-state="None" name="mode" @change="${this._OnModeChange}"></ui5-radio-button>
				<ui5-radio-button text="Scrivi URL" class="mode_url_on_nfc" value-state="None" name="mode" @change="${this._OnModeChange}"></ui5-radio-button>
			</field>
		</div>
		${this.fn()}
		`;
	}

	GetCollectionsFromModels() {
		const collections = this.models_list.map(model => model.line)
		return collections.filter((collection, index) => collections.indexOf(collection) === index)
	}

	GetModelsByCollection(collection) {
		return this.models_list.filter(model => model.line == collection)
	}

	RenderForm() {
		// Di default è 1x1px trasparente
		let md_src = this.TransparentImageSrc
		let url = ""

		if (this.current_model != "") {
			const mdf = this.models_list.find(md => md.id == this.current_model)
			if (mdf != null) {
				md_src = mdf.media_url
				url = mdf.url
			}
		}
		return html`
		<div class="row form">
			<div class="col">				
				<field>
					<ui5-label show-colon >Collezione - Linea</ui5-label>
					<select class="select collection" value="" @change="${this._OnCollectionChange}">
						<option value="" disabled selected>Seleziona la collezione</option>
						${this.GetCollectionsFromModels().map(collection => html`<option value="${collection}">${collection}</option>`)}
					</select>						
				</field>
				<field>
					<ui5-label show-colon >Modello - Codice</ui5-label>
					<select class="select model" value="" @change="${this._OnModelChange}">
						<option value="" disabled selected>Seleziona il modello</option>
					</select>						
				</field>
				<field class="hidden">
					<ui5-label required show-colon >Seriale</ui5-label>
					<ui5-input class="serial" value="${this.serial}"></ui5-input>
				</field>
				<field class="hidden">
					<ui5-label required show-colon >Numero</ui5-label>
					<ui5-input class="number" value="${this.number}"></ui5-input>
				</field>
				${
			NFC.IsSupported() ?
				html`
					<field>
						<ui5-label show-colon >URL da scrivere nel tag NFC</ui5-label>
						<ui5-input class="url" value="${url}"></ui5-input>
						<ui5-button design="Emphasized" @click="${() => { this._OnConfirmWriting() }}">SCRIVI</ui5-button>
						${this._RenderBeepElement()}
					</field>
				` :
				html``
			}
				<field class="horizontal NfcIndicator">
					<span class="scanning">Scansione chip nfc in corso: </span><ui5-busy-indicator class="scanning" active size="Small" style="width: 40px"></ui5-busy-indicator> 
					<span class="found hidden"></span>
					<div class="error hidden">Dispositivo di lettura/scrittura tag NFC non trovato</div>
				</field>
			</div>
			<div class="col">
				<field>										
					<figure>
						<img class="media_url" src="${md_src}" />
					</figure>
				</field>																				
			</div>
		</div>			
		`
	}

	RenderQrCodeScan() {
		return html`  
		<div class="row">	 
			<field>	
				<h2>Scanner QrCode attivo</h2>													
				<figure id="reader" style="height: 300px; ${this.camera.id != window.Sets.QrCodeCameraId ? 'width: 300px;' : 'max-width: 484px;'} margin: auto;"></figure>
			</field>					
		</div>			
		`
	}

	RenderUrlForm() {
		if (NFC.IsSupported() == false)
			return html`
				<div class="row">
					<field class="horizontal NfcIndicator">
						<div class="error">Dispositivo di lettura/scrittura tag NFC non trovato</div>
					</field>
				</div>
			`;
		return html`
			<div class="row">
				<field class="NfcInterface">
					<ui5-label show-colon >Indirizzo web da scrivere nel tag NFC</ui5-label>
					<div class="horizontal">
						<ui5-input @keyup="${() => { this._OnUrl() }}" class="text" value="https://www.re49.it"></ui5-input>
					</div>
					<ui5-button design="Emphasized" @click="${() => { this._OnConfirmWriting() }}">SCRIVI</ui5-button>
					${this._RenderBeepElement()}
				</field>
			</div>
			<div class="row">
				<field class="horizontal NfcIndicator hidden">
					<span class="scanning">Scansione chip nfc in corso: </span><ui5-busy-indicator class="scanning" active size="Small" style="width: 40px"></ui5-busy-indicator> 
					<span class="found hidden"></span>
					<div class="error hidden">Dispositivo di lettura/scrittura tag NFC non trovato</div>
				</field>
			</div>
		`
	}

	_RenderBeepElement() {
		return html`
			<div class="hidden">
				<audio src="${beep}"></audio>
			</div>
		`;
	}

	updated(changedProperties) {
		if (changedProperties.has('mode')) {
			if (this.mode == this.MODE.QRCODE_SCAN) {
				setTimeout(() => { this.QrCodeCameraActivate() }, 10)
				this._NfcReadStop()
				NFC.Stop()
			}
			if (this.mode == this.MODE.FORM_MANUAL || this.mode == this.MODE.FORM_AUTO) {
				setTimeout(() => { this.QrCodeCameraStop() }, 10)
				NFC.Stop()
				if (NFC.IsSupported())
					this._NfcReadStart()
				else
					this._NfcReadError()
			}
			if (this.mode == this.MODE.FORM_WRITE_URL) {
				setTimeout(() => { this.QrCodeCameraStop() }, 10)
				this._NfcReadStop()
				NFC.Stop()
			}
		}
		setTimeout(() => {
			let cn = this.querySelector(".collection")
			if (cn != null) {
				cn.value = this.models_list.find(md => md.id == this.current_model).line
				cn.dispatchEvent(new Event("change"));
			}
		}, 5)
	}

	async _LoadList(data_store) {
		return await data_store.GetList()
	}

	async _LoadLines() {
		let dt = []
		let data = await DataProvider.Models.GetList()
		data.forEach((row) => {
			let line = row["line"]
			if (dt.indexOf(line) < 0)
				dt.push(line)
		});

		return dt
	}

	async _OnCollectionChange(evt) {
		let md = this.querySelector(".model");
		if (md != null) {
			md.innerHTML = `<option value="" selected="true" disabled="disabled">Seleziona il modello</option>
			${this.GetModelsByCollection(evt.currentTarget.value).map(model => `<option value="${model.id}">${model.name + " - " + model.code}</option>`)}`
			if (this.current_model != "")
				md.value = this.current_model
		}
	}

	async _OnModelChange(evt) {
		this.current_model = evt.currentTarget.value
		const model = this.models_list.find(model => model.id == this.current_model)
		this.querySelector('.url').value = model.url;
	}

	async _OnModeChange(evt) {
		if (this.querySelector(".mode_manual").checked == true) {
			this.serial = ""
			this.number = ""
			this.current_model = ""
			this.mode = this.MODE.FORM_MANUAL
		} else if (this.querySelector(".mode_url_on_nfc").checked == true) {
			this.mode = this.MODE.FORM_WRITE_URL
		} else if (this.querySelector(".mode_auto").checked == true) {
			this.mode = this.MODE.QRCODE_SCAN
		}

		window.localStorage.setItem("production_finization_mode", this.mode)
	}

	_OnUrl() {
		let url = this.querySelector(".NfcInterface ui5-input").value;
	}

	_GetUrlFromField() {
		let field = this.querySelector(".NfcInterface ui5-input") || this.querySelector("ui5-input.url")
		let url = field.value;
		if (url.startsWith("https://") == false) {
			if (url.indexOf("://") > -1)
				url = url.substring(url.indexOf("://") + 3);
			url = "https://" + url;
			field.value = url;
		}

		return url
	}

	async _OnConfirmWriting() {
		let url = this._GetUrlFromField()
		await this._NfcWrite(url)
	}

	get _nfcIndicatorStates() {
		return {
			SCANNING: "SCANNING",
			SCANNED: "SCANNED",
			ERROR: "ERROR",
			HIDDEN: "HIDDEN"
		}
	}

	_ShowNfcIndicator(status) {
		switch (status) {
			case this._nfcIndicatorStates.SCANNING:
				this.querySelector(".NfcIndicator .found").classList.add("hidden")
				this.querySelector(".NfcIndicator .found").innerHTML = ""
				this.querySelectorAll(".NfcIndicator .scanning").forEach(el => el.classList.remove("hidden"))
				this.querySelector(".NfcIndicator .error").classList.add("hidden")
				this.querySelector('.NfcIndicator').classList.remove('hidden')
				break;
			case this._nfcIndicatorStates.SCANNED:
				this.querySelectorAll(".NfcIndicator .scanning").forEach(el => el.classList.add("hidden"))
				this.querySelector(".NfcIndicator .error").classList.add("hidden")
				this.querySelector(".NfcIndicator .found").classList.remove("hidden")
				break;
			case this._nfcIndicatorStates.ERROR:
				this.querySelector(".NfcIndicator .found").classList.add("hidden")
				this.querySelector(".NfcIndicator .found").innerHTML = ""
				this.querySelectorAll(".NfcIndicator .scanning").forEach(el => el.classList.add("hidden"))
				this.querySelector(".NfcIndicator .error").classList.remove("hidden")
				break;
			case this._nfcIndicatorStates.HIDDEN:
				this.querySelector('.NfcIndicator').classList.add('hidden');
				break;
		}
	}

	async _NfcWrite(url) {
		this._NfcReadStop();
		NFC.Stop();
		try {
			let url_validator = new URL(url);
			// if (this.nfc_url == "" || window.confirm("Sei sicuro di voler sovrascrivere il contenuto del tag NFC?")) {
			try {
				this.querySelector('ui5-button').setAttribute('disabled', '');
				this._ShowNfcIndicator(this._nfcIndicatorStates.SCANNING);
				await NFC.Write(url, { type: NFC.types.URL });
				// let records = await NFC.Read();
				try {
					const audio = this.querySelector('audio');
					if (audio) {
						audio.play();
					}
				} catch (error) {
					console.error(error);
				}
				alert(`Scrittura avvenuta con successo!`);
			} catch (error) {
				console.error(error);
				//alert("Si è verificato un errore durante la scrittura del tag NFC. Riprova.");
				console.debug("Si è verificato un errore durante la scrittura del tag NFC.");
				if (error.error_code != NFC.error_codes.TIMEOUT.error_code) {
					setTimeout(() => {
						this._NfcWrite(url);
					}, 1)
					return;
				}
			}
			// }
		} catch (error) {
			console.error("URL non valido");
			console.error(error)
		}
		this.querySelector('ui5-button').removeAttribute('disabled');
		if (this.mode != this.MODE.FORM_WRITE_URL) {
			this._ShowNfcIndicator(this._nfcIndicatorStates.SCANNING);
			setTimeout(() => {
				this._NfcReadStart();
			}, 1)
		}
		else
			this._ShowNfcIndicator(this._nfcIndicatorStates.HIDDEN);
	}

	_NfcReadStart() {
		if (this.nfc_read_timer_handle != null)
			return

		this.nfc_read_timer_handle = setTimeout(async () => {
			try {
				let data = await NFC.Read(5_000)
				if (data.length)
					data = data[0];
				console.debug(data);
				return this._NfcReadSuccess(data);
			} catch (error) {
				console.debug(error);
				// this._NfcReadError()
				if (error.error_code != NFC.error_codes.TIMEOUT.error_code)
					alert("Si è verificato un errore durante la lettura del tag NFC. Riprova.");
			}
			this.nfc_read_timer_handle = null
			this._NfcReadStart()
		}, 1000)
	}

	_NfcReadStop() {
		clearTimeout(this.nfc_read_timer_handle)
		this.nfc_read_timer_handle = null
	}

	async _NfcReadSuccess(data) {

		this.nfc_url = data;

		this._ShowNfcIndicator(this._nfcIndicatorStates.SCANNED);
		this.querySelector(".NfcIndicator .found").innerHTML = `Contenuto: ${data}` // Trovato chip ID: ${data.chip_uuid} - 

		setTimeout(async () => {
			if (this.mode != this.MODE.FORM_WRITE_URL) {
				try {
					// let model_data = await DataProvider.Models.Get(this.current_model)

					// let SerialNumber = this.querySelector(".serial").value
					// console.log(SerialNumber)
					// //QmbTwxx7bJ7EmQZhhMUfUY7WP3SA9UPnT1ij1hTuKV2196 <= vecchio template
					// //
					// let ret = await Notarizator.Notarize(
					// 	model_data.name + "_" + (new Date().getTime() / 1000),
					// 	window.Sets.IpfsGateway + window.Sets.NotarizazionTemplateHash,
					// 	model_data,
					// 	SerialNumber
					// )

					// console.log(ret)

					// url = ret
				}
				catch (Ex) {
					console.log(Ex)
					alert("Notarizzazione fallita")
					url = ""
				}
			}

			this.nfc_read_timer_handle = null
			this._NfcReadStart()
		}, 10)
	}

	_NfcReadError() {
		this._ShowNfcIndicator(this._nfcIndicatorStates.ERROR)
	}

	async _OnNotarize() {

		let batch = this.querySelector(".batch")
		let model = this.querySelector(".model")

		if (model.value.trim() == "") {
			model.valueState = "Error"
			model.focus()
			return
		}

		if (this.total_pieces == 0) {
			model.valueState = "Error"
			model.focus()
			return
		}

		this.data["model"] = model.value.trim()
		this.data.pieces_map = {}
		this.pieces_array.forEach((el) => {
			this.data.pieces_map[el.n] = el.q
		})

		this.WaiterShow()
		await DataProvider.ProductionBatches.Save(this.data, this.record_id)
		this.WaiterHide()

		return this.Ok()
	}

	async Show(id) {
		this.models_list = await this._LoadList(DataProvider.Models)
		this.camera = await this.QrCodeCameraDetect()
		if (this.camera == null)
			alert("QrCode Scanner Camera non connessa o non funzionante")

		return IVPDialog.Show(this)
	}

	async Init() {
		this.mode = window.localStorage.getItem("production_finization_mode")
		if (this.mode == this.MODE.FORM_MANUAL)
			this.querySelector(".mode_manual").checked = true
		else if (this.mode == this.MODE.FORM_WRITE_URL)
			this.querySelector(".mode_url_on_nfc").checked = true
	}

	async UnInit() {
		this.QrCodeCameraStop()
		this._NfcReadStop()
		NFC.Stop();
	}

	async QrCodeCameraDetect() {
		try {
			let devices = await Html5Qrcode.getCameras()

			if (devices == null || devices.length == 0)
				throw "No device found"

			let camera = devices.find(d => d.id == window.Sets.QrCodeCameraId)
			if (camera == null) {
				// throw `Camera with id:${window.Sets.QrCodeCameraId} not found`
				console.error(`Camera with id:${window.Sets.QrCodeCameraId} not found`);
				camera = devices.length > 1 ? devices[1] : devices[0];
			}

			return camera
		}
		catch (Ex) {
			console.log(Ex)
		}

		return null;
	}

	async QrCodeCameraActivate() {
		try {
			if (this.camera == null ||
				this.camera_activating == true ||
				this.querySelector("#reader") == null)
				return

			this.camera_activating = true
			this.html5QrCode = new Html5Qrcode("reader");
			await this.html5QrCode.start(
				this.camera.id,
				{
					fps: 5,    // Optional, frame per seconds for qr code scanning
					qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
				},
				async (decodedText, decodedResult) => {
					if (this.mode != this.MODE.QRCODE_SCAN)
						return
					console.log(decodedText)
					let log = DataProvider.ProductionLog.DecodeQrCodeString(decodedText)
					if (log != null) {
						this.mode = this.MODE.FORM_AUTO
						let batchid = DataProvider.ProductionLog.GetBatchFromSerial(log.serial)
						let batch = await DataProvider.ProductionBatches.Get(batchid.toString())

						this.serial = log.serial
						this.number = log.number
						this.current_model = (batch == null ? "" : batch.model)

					}
				},
				errorMessage => {
					console.log(errorMessage);
				}
			)
		}
		catch (Ex) {
			console.log(Ex)
		}

		this.camera_activating = false
		return null;
	}

	async QrCodeCameraStop() {
		try {
			if (this.html5QrCode != null)
				await this.html5QrCode.stop()
		}
		catch (Ex) {
			console.log(Ex)
		}
	}

	_OnCancel() {
		this.serial = ""
		this.number = ""
		this.current_model = ""
		this.mode = this.MODE.QRCODE_SCAN
	}

}

ProductionFinalizationDialog.RegisterElement()