import { html } from 'lit';
import IVPLitElementBase from './lit-element-base';
import IVPLoader from "../components/loader"

import "./login.scss"
import logo from "../images/logo_gray.svg"

export default class IVPLogin extends IVPLitElementBase {
	
	static get tag() { return 'ivp-login' }

	constructor() {
		super()
	
	}

	render() {
		return html`
			<div>
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 494.604 264.037" height="100px">
					<g>
						<path transform="matrix(1,0,0,-1,141.4997,120.941089)" d="M 0 0 C 0 3.168 -1.197 5.913 -3.59 8.236 C -5.984 10.559 -8.746 11.72 -11.878 11.72 L -34.685 11.72 L -34.685 -11.72 L -11.878 -11.72 C -8.676 -11.72 -5.895 -10.577 -3.538 -8.288 C -1.18 -6.036 0 -3.274 0 0 M 22.807 -60.5 L 7.549 -75.6 L -34.685 -34.315 L -34.685 -54.06 L -56.278 -54.06 L -56.278 32.89 L -11.878 32.89 C -2.623 32.89 5.262 29.705 11.774 23.335 C 18.355 16.964 21.645 9.186 21.645 0 C 21.645 -8.06 19.006 -15.116 13.726 -21.17 C 8.552 -27.188 1.935 -30.92 -6.123 -32.361 Z " fill="#CCC"/>
						<path transform="matrix(1,0,0,-1,236.1574,174.63199)" d="M 0 0 L -67.047 0 L -67.047 86.581 L 0 86.581 L 0 65.516 L -45.455 65.516 L -45.455 53.849 L -10.822 53.849 L -10.822 32.784 L -45.455 32.784 L -45.455 21.064 L 0 21.064 Z " fill="#CCC"/>
						<path transform="matrix(1,0,0,-1,317.3537,174.63199)" d="M 0 0 L -21.593 0 L -21.593 15.838 L -78.397 15.838 L -46.141 87.003 L -26.503 78.433 L -45.19 36.955 L -21.593 36.955 L -21.593 86.581 L 0 86.581 Z " fill="#CCC"/>
						<path transform="matrix(1,0,0,-1,388.0412,131.23558)" d="M 0 0 C 0 6.195 -2.253 11.456 -6.757 15.785 C -11.228 20.149 -16.56 22.332 -22.754 22.332 C -29.019 22.332 -34.387 20.185 -38.855 15.891 C -43.29 11.597 -45.507 6.336 -45.507 .106 C -45.507 -6.123 -43.29 -11.422 -38.855 -15.785 C -34.456 -20.149 -29.124 -22.332 -22.859 -22.332 C -16.596 -22.332 -11.21 -20.186 -6.704 -15.891 C -2.235 -11.562 0 -6.265 0 0 M 21.593 0 C 21.593 -10.488 17.633 -20.344 9.714 -29.564 L -32.882 -78.896 L -49.104 -64.825 L -29.881 -42.868 C -40.405 -41.46 -49.256 -36.586 -56.437 -28.244 C -63.51 -19.973 -67.048 -10.488 -67.048 .211 C -67.048 12.388 -62.72 22.684 -54.061 31.095 C -45.474 39.436 -35.037 43.607 -22.754 43.607 C -10.612 43.607 -.176 39.332 8.552 30.778 C 17.246 22.226 21.593 11.966 21.593 0 " fill="#CCC"/>
					</g>
					<g>
						<path transform="matrix(1,0,0,-1,424.548,97.56558)" d="M 0 0 C -.527 .204 -1.27 .306 -2.229 .306 L -3.162 .306 L -3.162 -3.953 L -1.671 -3.953 C -.772 -3.953 -.072 -3.773 .432 -3.414 C .935 -3.055 1.186 -2.474 1.186 -1.671 C 1.186 -.868 .791 -.312 0 0 M -7.008 -11.787 L -7.008 2.894 C -6.098 2.894 -4.734 2.89 -2.92 2.884 C -1.105 2.878 -.096 2.869 .107 2.857 C 1.27 2.773 2.234 2.521 3.001 2.103 C 4.307 1.384 4.959 .216 4.959 -1.401 C 4.959 -2.636 4.615 -3.526 3.926 -4.075 C 3.237 -4.623 2.39 -4.951 1.384 -5.058 C 2.306 -5.251 3.001 -5.533 3.468 -5.906 C 4.33 -6.604 4.762 -7.702 4.762 -9.2 L 4.762 -10.512 C 4.762 -10.655 4.771 -10.799 4.791 -10.942 C 4.81 -11.087 4.845 -11.23 4.896 -11.374 L 5.031 -11.787 L 1.365 -11.787 C 1.246 -11.32 1.165 -10.644 1.123 -9.757 C 1.081 -8.87 1 -8.271 .881 -7.96 C .688 -7.445 .329 -7.086 -.197 -6.882 C -.485 -6.763 -.923 -6.685 -1.51 -6.648 L -2.354 -6.595 L -3.162 -6.595 L -3.162 -11.787 Z M -8.984 3.253 C -11.093 1.097 -12.146 -1.462 -12.146 -4.42 C -12.146 -7.487 -11.075 -10.093 -8.931 -12.236 C -6.799 -14.381 -4.223 -15.453 -1.204 -15.453 C 1.803 -15.453 4.372 -14.375 6.505 -12.219 C 8.637 -10.051 9.703 -7.451 9.703 -4.42 C 9.703 -1.474 8.643 1.084 6.522 3.253 C 4.378 5.456 1.803 6.559 -1.204 6.559 C -4.247 6.559 -6.84 5.456 -8.984 3.253 M 8.068 -13.926 C 5.492 -16.417 2.401 -17.663 -1.204 -17.663 C -4.93 -17.663 -8.068 -16.388 -10.619 -13.836 C -13.171 -11.284 -14.447 -8.146 -14.447 -4.42 C -14.447 -.551 -13.069 2.665 -10.314 5.229 C -7.727 7.625 -4.689 8.823 -1.204 8.823 C 2.449 8.823 5.57 7.529 8.158 4.941 C 10.745 2.354 12.039 -.767 12.039 -4.42 C 12.039 -8.182 10.715 -11.351 8.068 -13.926 " fill="#CCC"/>
					</g>
				</svg>

				<field>
					<ui5-label required show-colon >Login Utente</ui5-label>
					<ui5-input class="user" placeholder="Inserisci credenziale utente" value="" @keypress="${this._OnKeyPress}"></ui5-input>
				</field>
				<ui5-button design="Emphasized" @click="${this._OnLogin}">Login</ui5-button>
			</div>
		`;
	}

	firstUpdated() {
		setTimeout(()=>{
			this.querySelector(".user").focus()
		},50)
	}

	_OnKeyPress(evt) {
		evt.currentTarget.removeAttribute("value-state")
		if (evt.code === 'Enter')
			this._OnLogin()
	}	

	async _OnLogin() {
		
		let user = this.querySelector(".user")
		
		if(user.value.trim() == "") {
			user.valueState = "Error"
			user.focus()
			return
		}

		IVPLoader.Show()
		if (await window.Auth.Login(user.value.trim()) == true) {
			window.location.href = "/"
			return
		} else {
			user.value = ""
			user.valueState = "Error"
			user.focus()			
		}
			
		IVPLoader.Remove()
	}

}
IVPLogin.RegisterElement()
