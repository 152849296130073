import { html } from 'lit';
import IVPLitElementBase from './lit-element-base';
import MobileDetect from 'mobile-detect'
import IVPLoader from "../components/loader"
import "./media-uploader"

import "@ui5/webcomponents/dist/Label"
import "@ui5/webcomponents/dist/Input"
import "@ui5/webcomponents/dist/TextArea"
import "@ui5/webcomponents/dist/ComboBox"


import "./dialog.scss"

export default class IVPDialog extends IVPLitElementBase {

	// E' una classe base static get tag() { return 'ivp-side-navigation' }

	static get properties() {
		return {

		};
	}

	constructor() {
		super()
		this.show_promise = null
		this.show_promise_resolve = () => { }

		this.title = "Base Dialog"
		this.confirm_button_label = "Ok"
	}

	render() {
		return html`
		<ui5-dialog header-text="Register Form" >
			<div slot="header"	class="dialog-header">
				<h1>${this.title}</h1>
				<ui5-icon @click="${this.OnCancel}" name="decline"></ui5-icon>
			</div>
			<div class="dialog-body">
				${this._RenderBody()}
			</div>
			${this._RenderFooter()}
		</ui5-dialog>		
		`
	}

	_RenderFooter() {
		if (this.confirm_button_label == "")
			return html``

		return html`
			<div slot="footer"	class="dialog-footer">
				<ui5-button @click="${this.OnConfirm}" design="Emphasized">${this.confirm_button_label}</ui5-button>
			</div>
		`
	}

	firstUpdated() {
		this.querySelectorAll("field > *").forEach((el) => {
			el.addEventListener("keypress", (evt) => {
				evt.currentTarget.removeAttribute("value-state")
			})
		})

		setTimeout(async () => { await this.Init() }, 5)
	}

	disconnectedCallback() {
		super.disconnectedCallback()

		this.UnInit()
	}

	_RenderBody() {
		return html``;
	}

	async Init() {

	}

	async UnInit() {

	}

	async OnConfirm() {
		return this.Ok()
	}

	async OnCancel() {
		return this.Cancel()
	}

	Ok() {
		this.show_promise_resolve("OK")
		this.remove()
	}

	Cancel() {
		this.show_promise_resolve("CANCEL")
		this.remove()
	}

	WaiterShow() {
		IVPLoader.Show(this.querySelector("ui5-dialog"))
	}

	WaiterHide() {
		IVPLoader.Remove()
	}

	static Show(dlg) {
		let pr = new Promise((resolve) => {
			//dlg.show_promise = pr
			dlg.show_promise_resolve = resolve

			document.body.append(dlg)
			setTimeout(() => {
				let uid5_dlg = dlg.querySelector("ui5-dialog")
				uid5_dlg.addEventListener("before-close", (evt) => {
					if (evt.detail.escPressed == true)
						dlg._Cancel()
				})
				let md = new MobileDetect(window.navigator.userAgent)

				if (md.mobile()) {
					uid5_dlg.stretch = true
					uid5_dlg.style.borderRadius = 0
				} else {
					uid5_dlg.classList.add("desktop")
				}
				uid5_dlg.show()
			}, 1)

		})

		return pr
	}
}