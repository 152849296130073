import { html } from 'lit';
import IVPDialog from '../components/dialog';
import IVPWidgetList from '../components/widget-list';
import Ipfs from '../libs/ipfs'

import DataProvider from '../data_provider'

import './model.scss';
import IVPLoader from '../components/loader';

export default class ModelDialog extends IVPDialog {

	static get tag() { return 'model-dialog' }

	constructor() {
		super()

		this.title = "Modifica Modello"
		this.item_name = ""
		this.record_id = null
		this.data = {
			line: "",
			name: "",
			description: "",
			code: "",
			media_url: "",
			phases: [],
			materials: [],
			url: ""
		}
		this.lines_list = []
		this.phases_list = []
		this.materials_list = []
	}

	_RenderBody() {
		return html`
		<div class="row">
			<div class="col">
				<field>
					<ui5-label required show-colon >Linea</ui5-label>
					<ui5-combobox  class="line"  placeholder="Digita o seleziona la linea" value="${this.data.line}">
						${this.lines_list.map((line) => {
			return html`
							<ui5-cb-item text="${line}"></ui5-cb-item>
							`
		})}						
					</ui5-combobox>			
				</field>
				<field>
					<ui5-label required show-colon >Nome</ui5-label>
					<ui5-input class="name" placeholder="Nome ${this.item_name}" value="${this.data['name']}"></ui5-input>
				</field>
				<field>
					<ui5-label required show-colon >Codice</ui5-label>
					<ui5-input class="code" placeholder="Codice" value="${this.data['code']}"></ui5-input>
				</field>
				<field class="description-wrapper">
					<ui5-label required show-colon>Descrizione</ui5-label>
					<ui5-textarea class="description" placeholder="Descrizione ${this.item_name}" maxlength="1024" show-exceeded-text value="${this.data['description']}"></ui5-textarea>
				</field>
				<field>
					<ui5-label show-colon>Caratteristiche</ui5-label>
					<ivp-widget-list class="features"></ivp-widget-list>
				</field>
			</div>
			<div class="col">										
				<field>
					<ivp-media-uploader media_url="${this.data["media_url"]}"></ivp-media-uploader>
				</field>
				<field>
					<ui5-label required show-colon >Pagina web del modello</ui5-label>
					<ui5-input class="url" placeholder="https://www.re49.it/it/collezioni.html" value="${this.data['url'] ? this.data['url'] : 'https://www.re49.it/it/collezioni.html'}"></ui5-input>
				</field>
			</div>
		</div>			
		<div class="row">
			<div class="col">
			<field>
			<ui5-label show-colon>Fasi di lavorazione</ui5-label>
			<ivp-widget-list class="phases"></ivp-widget-list>
		</field>
			</div>
			<div class="col">
			<field>
			<ui5-label show-colon>Materiali</ui5-label>
			<ivp-widget-list class="materials"></ivp-widget-list>
		</field>
			</div>				
		</div>							
		`;
	}

	async _LoadList(data_store) {
		return await data_store.GetList()
	}

	async _LoadLines() {
		let dt = []
		let data = await DataProvider.Models.GetList()
		data.forEach((row) => {
			let line = row["line"]
			if (dt.indexOf(line) < 0)
				dt.push(line)
		});

		return dt
	}

	async Show(id) {
		this.record_id = id
		if (id != null) {
			IVPLoader.Show()
			this.data = await DataProvider.Models.Get(id)
			IVPLoader.Remove()
		}
		this.lines_list = await this._LoadLines()
		this.phases_list = await this._LoadList(DataProvider.Phases)
		this.materials_list = await this._LoadList(DataProvider.Materials)

		return IVPDialog.Show(this)
	}

	async Init() {
		let phases = this.querySelector(".phases")
		phases.items = this.data.phases
		phases.selectable_items = this.phases_list
		phases.label_field = "code"

		let materials = this.querySelector(".materials")
		materials.items = this.data.materials
		materials.selectable_items = this.materials_list
		materials.label_field = "code"

		let features = this.querySelector(".features")
		features.items = this.data.features
	}

	async OnConfirm() {

		let line = this.querySelector(".line")
		let name = this.querySelector(".name")
		let description = this.querySelector(".description")
		let code = this.querySelector(".code")
		let media_uploader = this.querySelector("ivp-media-uploader")
		let phases = this.querySelector(".phases")
		let materials = this.querySelector(".materials")
		let features = this.querySelector(".features")
		let url = this.querySelector(".url")

		if (line.value.trim() == "") {
			line.valueState = "Error"
			line.focus()
			return
		}
		if (name.value.trim() == "") {
			name.valueState = "Error"
			name.focus()
			return
		}
		if (description.value.trim() == "") {
			description.valueState = "Error"
			description.focus()
			return
		}
		if (code.value.trim() == "") {
			code.valueState = "Error"
			code.focus()
			return
		}
		if (media_uploader.media_url == "") {
			media_uploader.error_string = "E' necessario impostare un'immagine"
			return
		}

		try {
			let _url = new URL(url.value)
		} catch (error) {
			url.valueState = "Error"
			url.focus();
			return;
		}

		this.data["line"] = line.value.trim()
		this.data["name"] = name.value.trim()
		this.data["full_name"] = this.data["line"] + "-" + this.data["name"]
		this.data["description"] = description.value.trim()
		this.data["code"] = code.value.trim()
		this.data["phases"] = phases.items
		this.data["materials"] = materials.items
		this.data["features"] = features.items
		this.data["url"] = url.value

		this.WaiterShow()
		if (media_uploader.media_url.startsWith("https://") == false) {
			let ipfs_hash = await Ipfs.UploadToPinata(this.data["name"], media_uploader.blob, Sets.Pinata_JWT)
			this.data["media_url"] = Sets.IpfsGateway + ipfs_hash
		}
		else {
			this.data["media_url"] = media_uploader.media_url
		}

		await DataProvider.Models.Save(this.data, this.record_id)

		this.WaiterHide()

		return this.Ok()
	}

	async OnCancel() {
		return this.Cancel()
	}
}

ModelDialog.RegisterElement()