import { html } from 'lit';
import IVPSectionBase from '../components/section';
import '../components/table';
import axios from 'axios'

import BatchDialog from "../dialogs/batch"
import DataProvider from '../data_provider';

import "./sections.scss";

import ProductionFinalizationDialog from "../dialogs/production-finalization"

export default class IVPSectionProductionLog extends IVPSectionBase {

	static get tag() { return 'ivp-section-production-log' }

    static get properties() {
        return {
			batch: { type: String }
        };
    }

    constructor() {
		super();	
		
		this.batch = ""
		this.batch_record = null
    }

    render() {
		return html`	
		<div class="card grow">		
			<ivp-table mode="MultiSelect" has_row_menu="false"></ivp-table>
		</div>
		`;
    }

	async Init() {
		
		this.batch_record = await DataProvider.ProductionBatches.Get(this.batch)

		this.table = this.querySelector('ivp-table')		
		this.table.breadcrumbs.push({
			path: "/archivi/produzione",
			title: "Visualizza lotti di produzione",
			content: html`<span>Archivio Produzione</span>`
		})
		this.table.title = "Lotto: "+this.batch+" - Modello: " + this.batch_record.model_full_name
		this.table.actions = [
			html`<ui5-button @click="${()=>{this._OnLottoBtn()}}">Modifica lotto</ui5-button>`
		] 
		this.table.columns = [
			{
				field: 'serial',
				caption: 'Seriale',
				width: '30%'				
			},
			{
				field: 'number',
				caption: 'Numero',
				width: '30%'				
			},
			{
				field: 'blockchain_ref',
				caption: 'Notarizzato',
				width: '40%',
				formatter: (record)=>{ 
					let val = record["blockchain_ref"]
					
					if (val!=null && val.trim()!="")
						return html`<a href="${val}" target="_blank">SI</a>`
				}		
			}			
		] 
		this.table.selected_actions = [
			html`<ui5-button @click="${()=> { this.OnPrintLabels() }}">Stampa etichette</ui5-button>`,
			html`<ui5-button @click="${this.OnPrintProductionSheet}">Stampa distinta di produzione</ui5-button>`,
		]
		this.table.orderby = [ { field: "serial", direction: "asc"},{ field: "number", direction: "asc"},  ]	
		this.table.filter = [ { field: "batch",condition: "==", value: parseInt(this.batch) } ]
		await this.table.SetDataStore(DataProvider.ProductionLog)		
			  
	}

	async OnPrintLabels() {
		console.log(this.table.selected_rows)
		console.log(this.table.selected_rows[0].getAttribute("row-id"))
		let recs = await DataProvider.ProductionLog.GetList({ field: "batch",condition: "==", value: parseInt(this.batch) })

		let vd = []
		for(let i=0;i<this.table.selected_rows.length;i++) {
			let rid = parseInt(this.table.selected_rows[i].getAttribute("row-id"))
			let record = recs.find(r => r.id.toString() == rid.toString())
			if (record != null) {
				vd.push({
					full_name : this.batch_record.model_full_name,
					collection : this.batch_record.model_collection,
					line : this.batch_record.model_line,
					model : this.batch_record.model_name,
					code : this.batch_record.model_code,
        			serial : record.serial,
        			number : record.number,
        			logo: "https://firebasestorage.googleapis.com/v0/b/re49-blockchain-notarizer.appspot.com/o/images%2FRe49-Logo-52x24.png?alt=media&token=27d35611-f226-4561-a634-619163754343"
				})
			}
		}

		await axios.post(`${window.Sets.ServiceAddress}/label`,{labels: vd})
	}

	async _OnLottoBtn() {
		let dlg = new BatchDialog()
		let ret = await dlg.Show(this.batch)
		if (ret == "OK") 
			this.table.UpdateData()

		return null
	}
}

IVPSectionProductionLog.RegisterElement()

IVPRouter.Register("/archivi/produzione/{batch}", (params) => {	
    document.querySelector("#CONTENT").innerHTML = `<ivp-section-production-log batch="${params.batch}"></ivp-section-production-log>`
})