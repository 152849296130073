import { lit, html } from 'lit';
import IVPLitElementBase from './lit-element-base';
import MobileDetect from 'mobile-detect'
import IVPLoader from "../components/loader"


import "./side-navigation.scss";

export default class IVPSideNagation extends IVPLitElementBase {

	static get tag() { return 'ivp-side-navigation' }

    static get properties() {
        return {
			collapsed: { type: String},
			active_item: { type: String },
        };
    }

    constructor() {
		super();
		
		this.collapsed = false
		this.active_item = "HOME"
		this.vItems = []
		let md = new MobileDetect(window.navigator.userAgent)				
		this.is_mobile = md.mobile()
    }

    render() {
		return html`
			<ul>
				<li>					
					<a href="/" @click="${this._OnItemClick}"><ui5-icon name="home"></ui5-icon> Home</a></li>
				<li>
					<div>Archivi</div>
					<ul>
						<li><a href="/archivi/produzione" @click="${this._OnItemClick}"><ui5-icon name="badge"></ui5-icon> Produzione</a></li>
						<li><a href="/archivi/modelli" @click="${this._OnItemClick}"><ui5-icon name="product"></ui5-icon> Modelli</a></li>
						<li><a href="/archivi/materiali" @click="${this._OnItemClick}"><ui5-icon name="dimension"></ui5-icon> Materiali</a></li>
						<li><a href="/archivi/lavorazioni" @click="${this._OnItemClick}"><ui5-icon name="clinical-tast-tracker"></ui5-icon> Lavorazioni</a></li>
					</ul>
				<li>
				<li>
					<div>Impostazioni</div>
					<ul>
						<li><a href="/impostazioni/opzioni" @click="${this._OnItemClick}"><ui5-icon name="user-settings"></ui5-icon> Opzioni</a></li>
					</ul>
				<li>
				<li>
				<div>&nbsp;</div>
				<ul>
					<li><a href="/logout" @click="${this._OnItemClick}"><ui5-icon name="log"></ui5-icon> Esci</a></li>
				</ul>
			<li>				
			</ul>
		`;
    }

	firstUpdated() {
		this.vItems = this.querySelectorAll("a")

		this._UpdateActiveItem()
		setInterval(()=>{
			this._UpdateActiveItem()
		},100);

		this.collapsed = false
		if (this.is_mobile) 
			this.collapsed = true
	}

	updated(changedProp) {
		if (changedProp.has("collapsed")) {
			if (this.collapsed != true)
				this.classList.remove("collapsed")
			else
			this.classList.add("collapsed")
		}
	}

	_UpdateActiveItem() {
		this.vItems.forEach((el)=>{
			let elUrl = new URL(el.href).pathname

			if (elUrl == "/" && (window.location.pathname=="/" || window.location.pathname==""))
				el.setAttribute("active","true")
			else if (elUrl != "/" && window.location.pathname.startsWith(elUrl) == true)
				el.setAttribute("active","true")
			else
				el.removeAttribute("active")
			})
	}

	_OnItemClick(evt) {
		evt.preventDefault()		
		if (this.is_mobile) 
			this.collapsed = true

		let pathname = new URL(evt.currentTarget.href).pathname
		if (pathname == "/logout") {
			IVPLoader.Show()
			setTimeout(()=>{
				window.Auth.Logout()
				window.location.href = "/"
			},350)
		}
		else
			window.router.navigate(pathname)
	}
}

IVPSideNagation.RegisterElement()
