import { html } from 'lit';
import IVPSectionBase from '../components/section';
import '../components/table';

import PhaseDialog from "../dialogs/phase"
import DataProvider from '../data_provider';

import "./sections.scss";

export default class IVPSectionPhases extends IVPSectionBase {

	static get tag() { return 'ivp-section-phases' }

    static get properties() {
        return {
			
        };
    }

    constructor() {
		super();	
			
    }

    render() {
		return html`	
		<div class="card grow">		
			<ivp-table></ivp-table>
		</div>
		`;
    }

	async Init() {
		
		this.table = this.querySelector('ivp-table')
		this.table.title = "Archivio Lavorazioni"
		this.table.columns = [
			{
				field: 'name',
				caption: 'Nome',
				width: '20%'				
			},
			{
				field: 'description',
				caption: 'Descrizione',
				width: '55%'				
			},
			{
				field: 'code',
				caption: 'Codice',
				width: '25%'				
			}		
		] 
		this.table.orderby = [ { field: "name", direction: "asc"}]	
		await this.table.SetDataStore(DataProvider.Phases)

		this.table.OnAdd = async ()=>{
			let dlg = new PhaseDialog()
			let ret = await dlg.Show(null,{})
			if (ret == "OK") 
				return dlg.data			
			
			return null
		}
		this.table.OnEdit = async (id)=>{
			
			let dlg = new PhaseDialog()
			let ret = await dlg.Show(id)
			if (ret == "OK")
				return dlg.data

			return null
		}
				
		
	}
}

IVPSectionPhases.RegisterElement()

IVPRouter.Register("/archivi/lavorazioni", () => {
    document.querySelector("#CONTENT").innerHTML = "<ivp-section-phases></ivp-section-phases>"
})