import { initializeApp } from 'firebase/app';
import { getFirestore, collection, query, where, setDoc, doc, getDocs, deleteDoc, getDoc, addDoc, orderBy } from "firebase/firestore";

import DataStoreBase from "./libs/data_store"

class DataStoreSettings extends DataStoreBase {

	constructor() {
		super(window.db.settings)

		this.UpdateGlobalObject()
	}

	async UpdateGlobalObject() {
		let vst = await this.GetList()
		window.Sets = {}
		vst.forEach((st) => {
			window.Sets[st.name] = st.value
		})
	}
}

class DataStoreModels extends DataStoreBase {

	constructor() {
		super(window.db.models)


	}

	async UpdateLinkedTables() {
		this.materials = await (new DataStoreBase(window.db.materials)).GetList()
		this.phases = await (new DataStoreBase(window.db.phases)).GetList()
	}

	FillRow(row) {
		let vmo = []
		row.materials.forEach(async (mt) => {
			let mo = this.materials.find(m => m.id == mt.id)
			if (mo == null)
				vmo.push(mt)
			else
				vmo.push(mo)
		})
		row.materials = vmo
		let vpo = []
		row.phases.forEach(async (ph) => {
			let po = this.phases.find(p => p.id == ph.id)
			if (po == null)
				vpo.push(ph)
			else
				vpo.push(po)
		})
		row.phases = vpo

		return row
	}
}


class DataStoreBatches extends DataStoreBase {

	constructor() {
		super(window.db.production_batches)
		this.prod_log = window.db.production_log
	}

	async UpdateLinkedTables() {
		this.models = await (new DataStoreBase(window.db.models)).GetList()
	}

	FillRow(row) {
		let vmo = []
		let model = this.models.find(m => m.id == row.model)
		row.model_full_name = model.full_name
		let collection_and_line = model.line.split("-")
		let collection = collection_and_line && collection_and_line[0] ? collection_and_line[0].trim() : "";
		let line = collection_and_line && collection_and_line[1] ? collection_and_line[1].trim() : "";
		row.model_collection = collection
		row.model_line = line
		row.model_name = model.name
		row.model_code = model.code

		return row
	}

	async GenerateNewBatchNumber() {
		let year = (new Date()).getFullYear()
		let q = query(this.db_archive, where("year_creation", "==", year))
		let batches = await getDocs(q)
		let ids = []
		batches.forEach((el) => {
			ids.push(parseInt(el.id))
		})
		ids.sort((a, b) => (a > b ? -1 : 1))
		if (ids.length == 0)
			return (year - 2000) * 1000 + 1

		return ids[0] + 1;
	}

	async UpdatePieces(data) {
		// Carica tutte le pieces del lotto
		// per ogni numero determina num_tot e num_notarized
		// se il numero_nuovo > num_notarized aggiunge
		// se il numero_nuovo < num_tot && numero_nuovo > num_notarized elimina
	}

	async Save(data, id) {
		if (id == null) {
			id = await this.GenerateNewBatchNumber()
			data.batch = id
		}
		await setDoc(doc(this.db_archive, id.toString()), data);
		await this.UpdatePieces(data)
	}

	async Delete(id) {
		let logs = await this.prod_log.GetList()
		let undeletable = false
		logs.forEach((l) => {
			if (l.blockchain_ref != "")
				undeletable = true
		})
		if (undeletable == true)
			return;
		await deleteDoc(doc(this.db_archive, id));
	}
}

class DataStoreProductionLog extends DataStoreBase {

	constructor() {
		super(window.db.production_log)

	}

	async GenerateNewSerial(batch_num) {
		let q = query(this.db_archive, where("batch", "==", batch_num))
		let batches = await getDocs(q)
		let ids = []
		batches.forEach((el) => {
			ids.push(parseInt(el.id))
		})
		ids.sort((a, b) => (a > b ? -1 : 1))
		if (ids.length == 0)
			return batch_num * 10000 + 1

		return ids[0] + 1;
	}

	DecodeQrCodeString(s) {
		let vs = s.split("|")
		if (vs == null || vs.length != 3)
			return null

		return {
			model: vs[0],
			serial: vs[1],
			number: vs[2],
		}
	}

	GetBatchFromSerial(serial) {
		return parseInt(serial.substring(0, 5))
	}
}




// Your web app's Firebase configuration
const firebaseConfig = {
	apiKey: "AIzaSyDvuvsfnGfLgjjPP8CUifzhjeeGDEnRJto",
	authDomain: "re49-blockchain-notarizer.firebaseapp.com",
	projectId: "re49-blockchain-notarizer",
	storageBucket: "re49-blockchain-notarizer.appspot.com",
	messagingSenderId: "172971031048",
	appId: "1:172971031048:web:452448759fdbac794b6fbd"
};


window.app = initializeApp(firebaseConfig);

window.db = getFirestore();
window.db.users = collection(window.db, "users")
window.db.settings = collection(window.db, "settings")
window.db.materials = collection(window.db, "materials")
window.db.phases = collection(window.db, "phases")
window.db.models = collection(window.db, "models")
window.db.production_batches = collection(window.db, "production_batches")
window.db.production_log = collection(window.db, "production_log")

const DataProvider = {
	Users: new DataStoreBase(window.db.users),
	Settings: new DataStoreSettings(window.db.settins),
	Materials: new DataStoreBase(window.db.materials),
	Phases: new DataStoreBase(window.db.phases),
	Models: new DataStoreModels(),
	ProductionBatches: new DataStoreBatches(),
	ProductionLog: new DataStoreProductionLog(),
}

export default DataProvider
window.DataProvider = DataProvider