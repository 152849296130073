import { html } from 'lit';
import IVPSectionBase from '../components/section';
import '../components/table';
import IVPLoader from "../components/loader"

import MaterialDialog from "../dialogs/material"
import DataProvider from '../data_provider';

import "./sections.scss";

export default class IVPSectionMaterials extends IVPSectionBase {

	static get tag() { return 'ivp-section-materials' }

    static get properties() {
        return {
			
        };
    }

    constructor() {
		super();	
			
    }

    render() {
		return html`	
		<div class="card grow">		
			<ivp-table></ivp-table>
		</div>
		`;
    }

	async Init() {
		
		this.table = this.querySelector('ivp-table')
		this.table.title = "Archivio Materiali"
		this.table.columns = [
			{
				field: 'name',
				caption: 'Materiale',
				width: '20%'				
			},
			{
				field: 'description',
				caption: 'Descrizione',
				width: '55%'				
			},
			{
				field: 'code',
				caption: 'Codice',
				width: '25%'
			}
		] 
		this.table.orderby = [ { field: "name", direction: "asc"}]	
		await this.table.SetDataStore(DataProvider.Materials)

		this.table.OnAdd = async ()=>{
			let dlg = new MaterialDialog()
			let ret = await dlg.Show(null,{})
			if (ret == "OK") 
				return dlg.data			
			
			return null
		}
		this.table.OnEdit = async (id)=>{		

			let dlg = new MaterialDialog()
			let ret = await dlg.Show(id)
			if (ret == "OK")
				return dlg.data

			return null
		}
				
		
	}
}

IVPSectionMaterials.RegisterElement()

IVPRouter.Register("/archivi/materiali", () => {
    document.querySelector("#CONTENT").innerHTML = "<ivp-section-materials></ivp-section-materials>"
})