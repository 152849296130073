
import { Liquid } from 'liquidjs'
import axios from 'axios'
import Ipfs from '../libs/ipfs'
import Utilities from "./utilities"

import {Mnemonic } from '../libs/lkscore-lib.min.js'
import {Networks } from '../libs/lkscore-lib.min.js'
import {Transaction } from '../libs/lkscore-lib.min.js'

import InsightClient from '../libs/insight_client'

export default class Notarizator {
	
	static async Notarize(name, template_url, model_data, serial) {

		model_data = Notarizator._ProcessDataModels(model_data, null, serial)

		let vblobs = await Notarizator._ProcessTemplate(template_url,model_data)

		let ipfs_hash = await Ipfs.UploadToPinata(name, vblobs, Sets.Pinata_JWT)

		//let res = await Notarizator.NotarizeOnLKSBlockchain(Sets.LKS_Mnemonics,210010001,ipfs_hash)

		return window.Sets.IpfsGateway+ipfs_hash
	}

	static async _ProcessTemplate(template_base_url, model_data) {
		
		try {
			if (template_base_url.endsWith("/") == true)
				template_base_url = template_base_url.substring(0, template_base_url.length - 1);

			// Carica la pagina preincipale e la renderizza
			let data = (await axios({
				method: 'GET',				
				responseType: 'text',
				url: `${template_base_url}/index.html`,
			})).data
			
			let template = Notarizator._ReplaceUrls(template_base_url,data)

			let liquidOptions = {strictVariables: true}       
			let engine = new Liquid()
			let parsed_tpl = await engine.parse(template)
			let out_data = await engine.render(parsed_tpl, model_data, liquidOptions)

			// Verifica e modifica tutti i file css che hanno url interni
			var el = document.createElement( 'html' )
			el.innerHTML = out_data
			
			let vcss = el.querySelectorAll( 'link[type="text/css"]' )

			let vout = [ ]

			for(let i=0; i<vcss.length; i++) {
				let css = vcss[i]
				if (css.href.startsWith(template_base_url) == true) {
			
					let css_data = await axios({
						method: 'GET',				
						responseType: 'text',
						url: css.href,
					})

					css_data = css_data.data

					let template = Notarizator._ReplaceUrls(template_base_url,css_data)
					let css_file_name = css.href.replace(template_base_url,"")
					if (css_file_name.startsWith("/") == true)
						css_file_name = css_file_name.substring(1)
					if (template != css_data) {
						vout.push({
							name: css_file_name,
							blob: new Blob([template], { type: 'text/plain' })
						})					
					}
				}
			}

			for(let i=0; i<vout.length; i++) {
				let css = vout[i]
				out_data = out_data.replace(template_base_url+"/"+css.name,"./"+css.name)
			}

			vout.push({
				name: "index.html",
				blob: new Blob([out_data], { type: 'text/plain' })
			},)

			return vout
		}
		catch (Ex){
			console.errror(Ex)        
		} 		

		return null
	}

	static _ReplaceUrls(template_base_url,data) {

		// Sostituisce tutti gli url con le parentesi giuste
		let template = data.replace(/url:\/\/{{/g,"{{")
		template = template.replace(/src="\//g,`src="${template_base_url}/`)
		template = template.replace(/href="\//g,`href="${template_base_url}/`)
		template = template.replace(/url\(\//g,`url(${template_base_url}/`)		
		
		return template
	}	

	static _ProcessDataModels(model_data, producer, serial) {

		let pha = []		
		model_data.phases.forEach((el)=>{
			pha.push({
				Description: el.description,
				Image: el.media_url,
			})
		})

		let mat = []		
		model_data.materials.forEach((el)=>{
			mat.push({
				Description: el.description,
				Image: el.media_url,
			})
		})
	
		let RenderData = {

			Model : {
				Name: model_data.name,
				Slogan: "",
				Description: model_data.description,
				Image: model_data.media_url,
				Characteristics: model_data.features,     
				Phases: pha,
				Materials: mat,
			},
			Product : {
				ProductionTimestamp: Utilities.FormatDate(new Date()),
				SerialNumber: serial
			},
			Producer : {
				Name: "Re49 Srl - Gonars (UD) - ITALY",
				GpsUrl: "https://www.google.it/maps/place/Eredi+Masolini+Raimondo+S.N.C.Di+Daniela+Bruno+E+Sergio+Masolini/@45.8962838,13.2319245,19z/data=!4m5!3m4!1s0x477bb9e0b1e66ce1:0x7137ad8c3ac5b852!8m2!3d45.8963127!4d13.2317564",
			},
			Ln: "it"

		}		

        
        return RenderData
    }

	static async NotarizeOnLKSBlockchain(mnemonic, id, ipfs_hash, fee, amount) {
		try {

			let payload = `id=${id},ipfs=${ipfs_hash}`

			if (fee==null)
				fee = 100000

			if (amount == null)
				amount = 100000

			let code = new Mnemonic(mnemonic);
			let xpriv1 = code.toHDPrivateKey(); 
			let out = xpriv1.derive("m/44'/896'/0'/0")
			
			let private_key = out.privateKey										

			let public_key = private_key.toPublicKey();					

			let vutxo = await InsightClient.GetAddressUtxo(public_key.toAddress(Networks.livenet))
			
			let tot = 0;
			vutxo.forEach((utxo)=>{
				utxo.outputIndex = utxo.vout
				tot += utxo.satoshis
			})
								
			var transaction = new Transaction()
				.fee(fee)
				.from(vutxo)
				.addData(payload)
				.to(public_key,amount)
				.change(public_key)
				.sign(private_key);
							
			let rawdata = transaction.toString()
			let res = await InsightClient.SendTransaction(rawdata)
			let txid = res.txid

			return txid
		}
		catch (Ex) {
			console.log(Ex)
		}

		return null;
	}
}