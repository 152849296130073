import { html } from 'lit';
import IVPLitElementBase from './lit-element-base';

import "@ui5/webcomponents-icons/dist/upload-to-cloud"

import "./media-uploader.scss";

export default class IVPMediaUploader extends IVPLitElementBase {

	static get tag() { return 'ivp-media-uploader' }

    static get properties() {
        return {
			media_url: { type: String },
			ratio: { type: Number },
			error_string: { type: String }
        };
    }

    constructor() {
        super();

		this.media_url = ""
		this.ratio = 16/9
		this.blob = null
		this.error_string = ""
    }

    render() {
		if (this.media_url == "") {
			return html`
				<input type="file" @change="${this._OnMediaLoaded}" class="file hidden" />
				<div title="Clicca per caricare un'immagine" @click="${this._LoadMedia}">
					<ui5-icon name="upload-to-cloud"></ui5-icon>
					<span>Clicca per caricare un'immagine</span>
				</div>
				<span class="error invisible">${this.error_string}</span>
			`;
		} else {
			return html`
				<div title="">		
					<img src="${this.media_url}" @click="${this._OnViewImage}"/>	
					<div class="remove" @click="${this._OnRemoveMedia}" title="Elimina l'immmagine">
						<ui5-icon name="delete"></ui5-icon>
					</div>		
				</div>
				<span class="error invisible">${this.error_string}</span>
			`;
		}
    }
    
	updated() {
		setTimeout(()=>{
			let rc = this.getBoundingClientRect()
			if (parseInt(this.ratio) > 0) {
				let h = rc.width / this.ratio
				this.style.height = h + "px"
				this.style.width = rc.width + "px"
			}

			if (this.error_string=="")
				this.querySelector(".error").classList.add("invisible")
			else
				this.querySelector(".error").classList.remove("invisible")
		},10)
	}

	_LoadMedia() {
		let inp = this.querySelector(".file")
		inp.click()
	}

	_OnMediaLoaded(evt) {		
		this.blob = evt.currentTarget.files[0]
		this.media_url = window.URL.createObjectURL(this.blob)
		this.error_string = ""
	}

	_OnRemoveMedia(evt) {
		this.blob = null
		this.media_url = ""
		this.error_string = ""
	}

	_OnViewImage(evt) {
		window.open(evt.currentTarget.src)
	}
}

IVPMediaUploader.RegisterElement()
