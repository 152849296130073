import { html } from 'lit';
import IVPLitElementBase from './lit-element-base';
import IVPLoader from "../components/loader"
import Utilities from "../libs/utilities"

import "./dialog.scss"

import IVPContextMenu from "./context-menu"

import "@ui5/webcomponents-icons/dist/add"
import "@ui5/webcomponents-icons/dist/edit"
import "@ui5/webcomponents-icons/dist/delete"
import "@ui5/webcomponents-icons/dist/overflow"
import "@ui5/webcomponents-icons/dist/feeder-arrow"

import "@ui5/webcomponents/dist/Table.js"
import "@ui5/webcomponents/dist/TableColumn.js"
import "@ui5/webcomponents/dist/TableRow.js"
import "@ui5/webcomponents/dist/TableCell.js"



import "./table.scss"

export default class IVPTable extends IVPLitElementBase {

	static get tag() { return 'ivp-table' }

    static get properties() {
        return {
			columns: { type: Array },
			title: { type: String },
			data_store: { type: Object },
			no_data_text: { type: String },
			has_show: { type: Boolean },
			has_row_menu: { type: String },
			mode: { type: String },
			filter: { type: Array },
			orderby: { type: Array }
        };
    }

	constructor() {
		super()
		this.breadcrumbs = [{
			path: "/",
			title: "Torna alla home",
			content: html`
				<ui5-icon name="home"></ui5-icon> 
				<span>&nbsp;Home</span>
			` 
		}]
		this.title = ""
		this.actions = [
			html`<ui5-button name="add" @click="${this._OnAddClick}" title="Aggiunge un record" >Nuovo</ui5-button>`,
		]
		this.data = []
		this.columns = []	
		this.data_store = null	
		this.no_data_text = "Caricamento dati"
		this.has_show = false
		this.has_row_menu = true
		this.mode = "None"
		this.filter = [ ] // { field: "", value: "" }
		this.orderby = [ ]
		this.selected_actions = [ ]
	}

	get selected_rows() {
		return this.querySelector("ui5-table").selectedRows
	}

	render() {
		
		this.has_row_menu = this.has_row_menu.toString().toLocaleLowerCase() == "true"

		return html `				
			<div class="card-heading">
				<div> 
					${this.breadcrumbs.map((bread)=>{
						return html`
							<div onclick="window.router.navigate('${bread.path}')" title="${bread.title}">			
								${bread.content}
							</div>
							<span>&nbsp;/&nbsp;</span>`
					})}
					<h3>					
						${this.title}
					</h3>
				</div>
				${this.actions.map((action)=>{
					return action
				})} 
			</div>
			<ui5-table sticky-column-header="true" no-data-text="${this.no_data_text}" mode="${this.mode}" @selectionChange="${this._OnSelectionChanged}">
				
			${this.columns.map((col)=>{
				return html`
				<ui5-table-column slot="columns" style="width: ${col.width?col.width:'auto'}">
					<span style="line-height: 1.4rem">${col.caption}</span>
				</ui5-table-column>`
			})}		
			<ui5-table-column slot="columns">
				<span>&nbsp;</span>
			</ui5-table-column>
					
			${this.data.map((row)=>{
				return this.RenderRow(row)				
			})}

			</ui5-table>		
			<nav>
				<h3>Azioni sui selezionati:</h3>
				${this.selected_actions.map((ac)=>{					
					return ac
					})
				}
			</nav>
	`;
	}

	async SetDataStore(data_store) {
		 
		await this.UpdateData(data_store)
		this.data_store = data_store
	}

	async UpdateData(data_store) {
		
		if (data_store == null)
			data_store = this.data_store

		IVPLoader.Show(this)
		this.data = await data_store.GetList(this.filter, this.orderby)
		IVPLoader.Remove()	
		if (this.data.length == 0)
			this.no_data_text = "Nessun record trovato"
	}	

	RenderRow(row) {
		return html`
			<ui5-table-row row-id="${row["id"]}" class="${this.has_show?'selectable':''}" @click="${this._OnRowClicked}">
				${this.columns.map((col)=>{
					let value = row[col.field]?row[col.field]:""					
					if (value.seconds != null)
						value = Utilities.FormatDate(new Date(value.seconds*1000),"DD-MM-YYYY")				
					
					if (col.formatter!=null)
						value = col.formatter(row)

					return html`
						<ui5-table-cell align="${col.align?col.align:'left'}">${value}</ui5-table-cell>
					`
				})}	
				<ui5-table-cell class="actions">	
					${this.has_show?html`							
						<ui5-icon name="feeder-arrow" @click="${this._OnShowClick}" title="Visualizza dettagli" ></ui5-icon>	
					`:''}
					${this.has_row_menu?html`
						<ui5-icon name="overflow" @click="${this._OnMenuClick}" title="Mostra menu" ></ui5-icon>
					`:''}
				</ui5-table-cell>
			</ui5-table-row>
		`
	}

	async _OnSelectionChanged(evt) {		
		this.OnSelectionChanged(evt.detail.selectedRows,evt.detail.previouslySelectedRows)
	}

	async _OnRowClicked(evt) {
		if (evt.currentTarget.classList.contains("selectable"))
			return this._OnShowClick(evt)
	}

	async _OnMenuClick(evt) {
		evt.stopPropagation()
		let element = { currentTarget: evt.currentTarget, stopPropagation: ()=>{ } }
		IVPContextMenu.Show(evt.currentTarget,[
			{ name: "show", "text": "Visualizza", hide: !this.has_show },
			{ name: "edit", "text": "Modifica", hide: false },
			{ name: "separator", hide: false },
			{ name: "delete", "text": "Elimina", hide: false },
		],(item)=>{
			if (item == "show")
				return this._OnShowClick(element)
			else if (item == "edit")
				return this._OnEditClick(element)
			else if (item == "delete")
				return this._OnDeleteClick(element)
		})
	}

	async _OnAddClick(evt) {
		let data = await this.OnAdd()
		if (data != null){					  
			await this.UpdateData(this.data_store)
			this.requestUpdate()
		}
	}

	async _OnEditClick(evt) {		
		let row = evt.currentTarget.closest("ui5-table-row")
		let id = row.getAttribute("row-id")		
		let data = await this.OnEdit(id)
		if (data != null){			
			await this.UpdateData(this.data_store)
			this.requestUpdate()
		}
	}

	async _OnDeleteClick(evt) {
		let row = evt.currentTarget.closest("ui5-table-row")
		let id = row.getAttribute("row-id")
		if (confirm("Confermi Eliminazione del record?") != true)
			return
		IVPLoader.Show(this)
		await this.data_store.Delete(id)
		row.remove()
		IVPLoader.Remove()
	}

	async _OnShowClick(evt) {
		evt.stopPropagation()
		let row = evt.currentTarget.closest("ui5-table-row")
		let id = row.getAttribute("row-id")		
		let data = await this.OnShow(id)
		if (data != null){			
			await this.UpdateData(this.data_store)
			this.requestUpdate()
		}		
	}

	async OnSelectionChanged(selected_rows,previous_selected_rows) {
		if (selected_rows.length > 0)
			this.SelectedRowsMenuShow() 
		else
			this.SelectedRowsMenuHide()
	}
	async OnAdd() {
		return null;
	}

	async OnEdit(id) {
		return null;
	}

	async OnShow(id) {
		return null;
	}

	SelectedRowsMenuShow() {
		if (this.selected_actions.length == 0)
			return

		this.classList.add("show_nav")
		setTimeout(()=>{
			let rc = this.querySelector("nav").getBoundingClientRect()
			this.querySelector("ui5-table").style.paddingBottom = (rc.height+2)+"px"
		},10)
	}

	SelectedRowsMenuHide() {
		this.classList.remove("show_nav")
		this.querySelector("ui5-table").style.paddingBottom = "0"
	}
	
}
IVPTable.RegisterElement()
