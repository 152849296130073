
import { html,render } from 'lit'
import IVPLitElementBase from './lit-element-base'

import Sortable from 'sortablejs';

import "@ui5/webcomponents-icons/dist/accept"
import "@ui5/webcomponents-icons/dist/decline"


import "./sortable-list.scss"

export default class IVPSortableList extends IVPLitElementBase {

	static get tag() { return 'ivp-sortable-list' }

    static get properties() {
        return {
			items: { type: Array },
			label_field: { type: String }
        };
    }

    constructor() {
        super();

		this.items = []
		this.sortable = null
		this.label_field = null
    }

    render() {

        return this.RenderList()
    }

	updated(changedProperties) {
		setTimeout(()=>{
			let el = this.querySelector('ul');
			this.sortable = Sortable.create(el,{
				handle: ".drag-handle",
				dragClass: "sortable-drag",
				animation: 150,
				onEnd: (evt) => {
					let dum = this.items[evt.oldIndex]
					this.items.splice(evt.oldIndex,1)
					this.items.splice(evt.newIndex,0,dum)
				}
			});
		},5)
	}

	RenderList() {
		if (this.items == null)
			this.items = []		
		if (this.sortable != null) {
			this.sortable.destroy()
			this.sortable = null
		}		
		return html`		
			<ul class="sortable-list">
			${this.items.map((item)=>{
				return this.RenderItem(item)
			})}			
			</ul>
		`;
	}


	RenderItem(item) {
		return html`			
			<li title="Trascina per riordinare la lista">
                ${this.RenderItemInternal(item)}
			</li>
		`
	}

	RenderItemInternal(item) {
		let label = item
		if (this.label_field != null)
			label = item[this.label_field]
		return html`
				<div>
					<svg class="drag-handle" viewBox="0 0 24 24">
						<path fill="currentColor" d="M7,19V17H9V19H7M11,19V17H13V19H11M15,19V17H17V19H15M7,15V13H9V15H7M11,15V13H13V15H11M15,15V13H17V15H15M7,11V9H9V11H7M11,11V9H13V11H11M15,11V9H17V11H15M7,7V5H9V7H7M11,7V5H13V7H11M15,7V5H17V7H15Z" />
					</svg>
					<span>${label}</span>
				</div>
				<ui5-icon @click="${(evt)=>{ this._OnDeleteItem(evt) }}" name="decline" title="Rimuove l'elemento dalla lista"></ui5-icon>
		`
	}

    async _OnDeleteItem(evt) {
		let li = evt.currentTarget.closest("li")
		let n = [...li.parentElement.children].indexOf(li)
		console.log(n )
		li.remove()
		this.items.splice(n,1)
	}

	AppendItem(item) {
		if (this.items.indexOf(item) < 0) {
			this.items.push(item)		
			let li =document.createElement("li")
			this.querySelector("ul").append(li)
			render(this.RenderItemInternal(item),li)
		}		
	}
}

IVPSortableList.RegisterElement()
