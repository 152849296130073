/**
 * Class representing a NFC interface to read and write NFC tags.
 */
class NFC {

    static IsSupported() {
        return 'NDEFReader' in window;
    }

    static get types() {
        return {
            ABSOLUTE_URL: "absolute-url", // An absolute URL to the data.
            EMPTY: "empty", // An empty NDEFRecord.
            MIME: "mime", // A valid MIME type.
            SMART_POSTER: "smart-poster",// A smart poster as defined by the NDEF-SMARTPOSTER [https://w3c.github.io/web-nfc/#bib-ndef-smartposter] specification.
            TEXT: "text", // Text as defined by the NDEF-TEXT [https://w3c.github.io/web-nfc/#bib-ndef-text] specification.
            UNKNOWN: "unknown", // The record type is not known.
            URL: "url", // A URL as defined by the NDEF-URI [https://w3c.github.io/web-nfc/#bib-ndef-uri] specification.
        }
    }

    static get error_codes() {
        return {
            TIMEOUT: { error_code: "TIMEOUT", error_msg: "Operation timed out." }, // Operation timed out.
            READ_ERROR: { error_code: "READ_ERROR", error_msg: "Error reading the tag." }, // Error reading the tag.
            WRITE_ERROR: { error_code: "WRITE_ERROR", error_msg: "Error writing the tag." }, // Error writing the tag.
            TAG_ERROR: { error_code: "TAG_ERROR", error_msg: "Error with the tag." }, // Error with the tag.
            NFC_ERROR: { error_code: "NFC_ERROR", error_msg: "Error with the NFC reader." } // Error with the NFC reader.
        }
    }

    constructor() {
        if (NFC._instance) {
            console.warn("Already instantiated.");
            return NFC._instance;
        }

        NFC._instance = this;

        this.ndef = null;
        this.ctlr = null;
        this.readTimeout = null;
    }

    static get instance() {
        if (NFC._instance == null)
            return new NFC();

        return NFC._instance;
    }

    Start() {
        return new Promise((resolve, reject) => {
            if (this.ndef == null) {
                this.ndef = new NDEFReader();
                this.ctlr = new AbortController();
                this.ctlr.signal.onabort = () => { console.log('WEB NFC READER STOPPED') };
                this.ndef.scan({ signal: this.ctlr.signal })
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    })

                this.ndef.addEventListener("readingerror", this.onReadingErrorEvent)
                this.ndef.addEventListener("reading", this.onReadingEvent)
            } else
                resolve();
        })
    }

    readingEvent() { }
    onReadingEvent = (event) => {
        // NDEF message read.
        clearTimeout(this.readTimeout);
        let data = [];
        const decoder = new TextDecoder();
        for (const record of event.message.records) {
            data.push(decoder.decode(record.data));
        }
        this.readingEvent(data);
    }

    readingErrorEvent() { }
    onReadingErrorEvent = (event) => {
        // Error! Cannot read data from the NFC tag. Try a different one?
        clearTimeout(this.readTimeout);
        this.readingErrorEvent(NFC.error_codes.TAG_ERROR);
    }

    /**
     * Stop scanning for tags (shut down NFC horn).
     */
    static Stop() {
        if (NFC._instance) {
            const This = NFC.instance;
            clearTimeout(This.readTimeout);
            This.readTimeout = null;
            if (This.ndef) {
                This.ndef.removeEventListener("readingerror", This.onReadingErrorEvent)
                This.ndef.removeEventListener("reading", This.onReadingEvent)
            }
            if (This.ctlr != null)
                This.ctlr.abort();
            This.ndef = null;
            This.ctlr = null;
        }
    }

    /**
     * Read NFC tag.
     * @param {number} [timeout=60000] - The number of ms after which the reader is stopped.
     */
    static Read(timeout = 60_000) {
        return new Promise((resolve, reject) => {
            const This = NFC.instance;
            This.readingEvent = resolve;
            This.Start()
                .then(() => {
                    // Scan started successfully.
                    if (timeout)
                        This.readTimeout = setTimeout(() => {
                            reject(NFC.error_codes.TIMEOUT);
                        }, timeout)
                })
                .catch(error => {
                    console.log(`Error! Scan failed to start: ${error}.`);
                    reject(NFC.GetErrorData(NFC.error_codes.NFC_ERROR, error));
                });
        })
    }

    /**
     * Write NFC tag.
     * @param {*} data - The data to write to the NFC tag.
     * @param {Object} options - The options for writing.
     * @param {number} [timeout=60000] - The number of ms after which the reader is stopped.
     * @param {string} type - The type of data to be written.
     */
    static Write(data, { timeout, type } = { timeout: 60_000, type: NFC.types.TEXT }) {
        return new Promise((resolve, reject) => {
            const This = NFC.instance;
            NFC.Read(timeout)
                .then(() => {
                    const ctlr = new AbortController();
                    ctlr.signal.onabort = () => { console.log('WRITE OPERATION STOPPED') };
                    This.ndef.write({ records: [{ recordType: type, data }] }, { signal: ctlr.signal, overwrite: true })
                        .then(() => {
                            resolve();
                        }, (error) => {
                            console.log(error);
                            ctlr.abort();
                            reject(NFC.GetErrorData(NFC.error_codes.WRITE_ERROR, error));
                        });
                })
                .catch(error => {
                    reject(error);
                });
        });
    }

    /**
     * Integrate class error handling with browser native errors.
     * @param {Object} error_obj - Class error object retrieved from error_codes static method.
     * @param {Object} error_data - Browser error object.
     */
    static GetErrorData(error_obj, error_data) {
        error_obj.error_data = error_data;
        return error_obj;
    }

}

NFC._instance = null;

export default NFC;