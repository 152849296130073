import { html } from 'lit';
import IVPLitElementBase from './lit-element-base';

import "@ui5/webcomponents/dist/Popover.js"

import "./context-menu.scss";

export default class IVPContextMenu extends IVPLitElementBase {

	static get tag() { return 'ivp-context-menu' }

    static get properties() {
        return {
			items: { type: Array },
        };
    }

    constructor() {
        super();
		this.items = [ ];
    }

    render() {
        return html`
		<ui5-popover placement-type="Bottom">
			<div class="popover-content">
				<ul>
					${this.items.map((i) => {
						if (i.hide == true)
							return 
						if (i.name.toLowerCase() == "separator")
							return html`<li class="separator"><div class="line"></div></li>`
						else
							return html`<li name="${i.name}" @click=${this._OnClickItem}>${i.text}</li>`
					})}
				</ul>      
			</div>
		</ui5-popover>		
              
        `;
    }

	firstUpdated() {
		let po = this.querySelector("ui5-popover")
		po.addEventListener("after-close",()=>{
			this.remove()
		})

		po.showAt(this.parent)
	}

    _OnClickItem(evt) {
        let ItemName = evt.target.getAttribute("name");
        let myEvent = new CustomEvent('item-selected', { detail: ItemName, bubbles: true, composed: true });
        this.dispatchEvent(myEvent);
    }

    static Show(elParent,vItems,ItemSelectCallback) {
		let ctx = document.body.querySelector("ivp-context-menu")
        let isSon = ctx!=null && ctx.parent == elParent
        document.querySelectorAll("ivp-context-menu").forEach((el)=> { el.remove(); })
        if (isSon == true)
            return;
        
        ctx = document.createElement("ivp-context-menu");
        ctx.addEventListener("item-selected",(evt) => { 
			ctx.remove()
			setTimeout(()=>{ ItemSelectCallback(evt.detail); },5)
		});
        ctx.items = vItems;
		ctx.parent = elParent
        document.body.append(ctx)
    }
}

IVPContextMenu.RegisterElement() 