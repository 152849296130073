import { html } from 'lit';
import IVPSectionBase from '../components/section';
import '../components/table';

import BatchDialog from "../dialogs/batch"
import DataProvider from '../data_provider';

import "./sections.scss";

export default class IVPSectionProductionBatches extends IVPSectionBase {

	static get tag() { return 'ivp-section-production-batches' }

    static get properties() {
        return {
			
        };
    }

    constructor() {
		super();	
			
    }

    render() {
		return html`	
		<div class="card grow">		
			<ivp-table has_show="true"></ivp-table>
		</div>
		`;
    }

	async Init() {
		
		this.table = this.querySelector('ivp-table')
		this.table.title = "Archivio Produzione"
		this.table.columns = [
			{
				field: 'id',
				caption: 'Lotto',
				width: '30%'				
			},
			{
				field: 'model_full_name',
				caption: 'Modello',
				width: '30%'				
			},
			{
				field: 'datetime',
				caption: 'Data',
				width: '40%'				
			}			
		] 
		this.table.orderby = [ { field: "batch", direction: "desc"} ]	
		await this.table.SetDataStore(DataProvider.ProductionBatches)		

		this.table.OnAdd = async ()=>{
			let dlg = new BatchDialog()
			let ret = await dlg.Show(null,{})
			if (ret == "OK") 
				return dlg.data			
			
			return null
		}
		this.table.OnEdit = async (id)=>{
			
			let dlg = new BatchDialog()
			let ret = await dlg.Show(id)
			if (ret == "OK")
				return dlg.data

			return null
		}
		this.table.OnShow = async (id)=>{
			window.router.navigate("/archivi/produzione/"+id)
			console.log("passa")
		}				
	}
}

IVPSectionProductionBatches.RegisterElement()

IVPRouter.Register("/archivi/produzione", () => {
    document.querySelector("#CONTENT").innerHTML = "<ivp-section-production-batches></ivp-section-production-batches>"
})