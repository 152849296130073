import { html } from 'lit';
import IVPSectionBase from '../components/section';
import '../components/table';
import IVPLoader from "../components/loader"

import ModelDialog from "../dialogs/model"
import DataProvider from '../data_provider';

import "./sections.scss";

export default class IVPSectionModels extends IVPSectionBase {

	static get tag() { return 'ivp-section-models' }

    static get properties() {
        return {
			
        };
    }

    constructor() {
		super();	
			
    }

    render() {
		return html`	
		<div class="card grow">		
			<ivp-table></ivp-table>
		</div>
		`;
    }

	async Init() {
		
		this.table = this.querySelector('ivp-table')
		this.table.title = "Archivio Modelli"
		this.table.columns = [
			{
				field: 'full_name',
				caption: 'Modello',
				width: '20%'
			},
			{
				field: 'description',
				caption: 'Descrizione',
				width: '70%'
			},
			{
				field: 'code',
				caption: 'Codice',
				width: '10%'				
			}
		] 	
		this.table.orderby = [ { field: "full_name", direction: "asc"}]	
		
		await this.table.SetDataStore(DataProvider.Models)

		this.table.OnAdd = async ()=>{

			let dlg = new ModelDialog()
			let ret = await dlg.Show(null)
			if (ret == "OK") 
				return dlg.data			
			
			return null
		}
		this.table.OnEdit = async (id)=>{
						
			let dlg = new ModelDialog()
			let ret = await dlg.Show(id)
			if (ret == "OK")
				return dlg.data

			return null
		}
				
		
	}
}

IVPSectionModels.RegisterElement()

IVPRouter.Register("/archivi/modelli", () => {
    document.querySelector("#CONTENT").innerHTML = "<ivp-section-models></ivp-section-models>"
})