import { html } from 'lit';
import IVPSectionBase from '../components/section';
import '../components/table';
import IVPLoader from "../components/loader"

import MaterialDialog from "../dialogs/material"
import DataProvider from '../data_provider';

import "./sections.scss";

export default class IVPSectionSettings extends IVPSectionBase {

	static get tag() { return 'ivp-section-settings' }

    static get properties() {
        return {
			
        };
    }

    constructor() {
		super();	
		
		this.data_store = DataProvider.Settings
    }

    render() {
		return html`	
		<div class="card grow">		
			<ivp-table></ivp-table>
		</div>
		`;
    }

	async Init() {
		
		this.table = this.querySelector('ivp-table')
		this.table.title = "Impostazioni"
		this.table.has_row_menu = false
		this.table.has_show = true
		this.table.columns = [
			{
				field: 'name',
				caption: 'Opzione',
				width: '20%'				
			},
			{
				field: 'value',
				caption: 'Impostazione',
				width: '80%'				
			}			
		] 
		this.table.actions = []
		this.table.orderby = [ { field: "name", direction: "asc"}]	
		await this.table.SetDataStore(this.data_store)

		this.table.OnShow = async (id)=>{
			let data = await this.data_store.Get(id)

			let newVal = window.prompt(`Inserisci nuovo valore per ${data.name}`,data.value)
			if (newVal == null)
				return null

			data.value = newVal
			await this.data_store.Save(data,id)
			
			return data
		}
			
		
	}
}

IVPSectionSettings.RegisterElement()

IVPRouter.Register("/impostazioni/opzioni", () => {
    document.querySelector("#CONTENT").innerHTML = "<ivp-section-settings></ivp-section-settings>"
})