import { html } from 'lit';
import IVPDialog from '../components/dialog';
import IVPWidgetList from '../components/widget-list';
import Ipfs from '../libs/ipfs'

import DataProvider from '../data_provider'

import "./batch.scss"
import IVPLoader from '../components/loader';


export default class BatchDialog extends IVPDialog {
	
	static get tag() { return 'bach-dialog' }

	static get properties() {
        return {
			current_model : { type: String },
			pieces_array : { type: Array }
        };
    }

	constructor() {
		super()
		
		this.title = "Modifica Lotto"
		this.record_id = null
		this.data = {
			batch: 0,
			year_creation: (new Date()).getFullYear(),
			model: "",
			datetime: new Date(),
			pieces_map: null
		}


		this.models_list = []
		this.model_image = ""
		this.current_model = this.data.model
		this.pieces_array = [
			{ n: 36, q: 2},
			{ n: 37, q: 2},
			{ n: 38, q: 2},
			{ n: 39, q: 2},
			{ n: 40, q: 2},
			{ n: 41, q: 2},
			{ n: 42, q: 2},
			{ n: 43, q: 2},
			{ n: 44, q: 2},
			{ n: 45, q: 2},
			{ n: 46, q: 2},			
		]	
		this.total_pieces = 0	
	}

	_RenderBody() {
		let nb = "Nuovo"
		if (this.data.batch > 0)
			nb = this.data.batch

		let md_src = this.TransparentImageSrc

		if (this.current_model != "") {
			let mdf = this.models_list.find(md => md.id==this.current_model)
			if (mdf != null)
				md_src = mdf.media_url
		}

		this.total_pieces = 0
		this.pieces_array.forEach((el)=> this.total_pieces += el.q)

		return html`
		<div class="row">
			<div class="col">
				<field>
					<ui5-label required show-colon >Lotto numero</ui5-label>
					<ui5-input class="batch" disabled value="${nb}"></ui5-input>
				</field>
				<field>
					<ui5-label required show-colon >Modello</ui5-label>
					<select class="select model" value="" @change="${this._OnModelChange}">
						<option value="" disabled>Seleziona il modello</option>
						${this.models_list.map((md)=>{						
							return html`
								<option value="${md.id}">${md.full_name}</option>
							`
						})}					
					</select>
					<figure>
						<img src="${md_src}" />
					</figure>
				</field>			
			</div>
			<div class="col">
				<field>
					<ui5-label wrapping-type="Normal">Inserisci il numero di pezzi per le varie misure che intendi produrre in questo lotto.</ui5-label>					
					<ui5-table sticky-column-header="true" no-data-text="${this.no_data_text}" mode="${this.mode}">
						
						<ui5-table-column slot="columns" style="width:40%" align="center">
							Numero
						</ui5-table-column>
						<ui5-table-column slot="columns" align="center">
							Pezzi
						</ui5-table-column>
						${this.pieces_array.map((el, index)=>{
							return html`			
								<ui5-table-row data-number="${index}" @click="${this._OnCellClick}">
									<ui5-table-cell  title="Clicca per modificare la quantità" align="center">		
										${el.n}	
									</ui5-table-cell>
									<ui5-table-cell  title="Clicca per modificare la quantità" align="center">		
										${el.q}	
									</ui5-table-cell>									
								</ui5-table-row>
							`
						})}

					</ui5-table>
					<ui5-label style="text-align: center; margin-top: 10px;">Numero totale di pezzi: ${this.total_pieces}</ui5-label>
				</field>
			</div>
		</div>
		`;
	}



	async _LoadList(data_store) {
		return await data_store.GetList()
	}

	async _LoadLines() {
		let dt = []
		let data = await DataProvider.Models.GetList()
		data.forEach((row) => {			
			let line = row["line"]			
			if (dt.indexOf(line) < 0)
				dt.push(line)
		});		

		return dt
	}	

	async _LoadPieces() {
		
		if (this.data.pieces_map != null) {
			this.pieces_array = []
			Object.keys(this.data.pieces_map).forEach((k)=>{
				this.pieces_array.push({
					n: k,
					q: this.data.pieces_map[k]
				})
			})
		}
	}
	
	async _OnModelChange(evt) {
		this.current_model = evt.currentTarget.value
	}

	async _OnCellClick(evt) {
		let index = parseInt(evt.currentTarget.getAttribute("data-number"))
		let ret = parseInt(prompt(`Immetti il numero di pezzi numero ${this.pieces_array[index].n} da produrre`, this.pieces_array[index].q))
		if (isNaN(ret)== false && ret >= 0) {
			this.pieces_array[index].q = ret
		}
		
		this.requestUpdate()
	}

	async Show(id) {
		this.models_list = await this._LoadList(DataProvider.Models)
		
		this.record_id = id
		if (id != null) {			
			IVPLoader.Show()
			this.data = await DataProvider.ProductionBatches.Get(id)
			IVPLoader.Remove()
			this.current_model = this.data.model
			this._LoadPieces()			
		}		

		return IVPDialog.Show(this)
	}	

	async Init() {
		this.querySelector(".model").value = this.data.model
	}

	async OnConfirm() {
		
		let batch = this.querySelector(".batch")
		let model = this.querySelector(".model")
				
		if(model.value.trim() == "") {
			model.valueState = "Error"
			model.focus()
		 	return
		}		
		
		if (this.total_pieces == 0) {
			model.valueState = "Error"
			model.focus()
		 	return
		}

		this.data["model"] = model.value.trim()
		this.data.pieces_map = {}
		this.pieces_array.forEach((el)=>{
			this.data.pieces_map[el.n] = el.q
		})

		this.WaiterShow()		
		await DataProvider.ProductionBatches.Save(this.data, this.record_id)		
		this.WaiterHide()

		return this.Ok()
	}

	async OnCancel() {
		return this.Cancel()
	}	
}

BatchDialog.RegisterElement()